import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "./store";

const initialState = {
  loading: true,
  title: "",
  children: undefined,
  search: true,
  list: {
    "Dashboard": [
      {
        title: "Dashboard",
        link: "/dashboard",
      },
    ],
    "Manage Customers": [
      {
        title: "Manage Customers",
        link: "/dashboard/manage-customers",
      },
    ],
    "Master Category": [
      {
        title: "Master Category",
        link: "/dashboard/manage-customers",
      },
    ],
    "Restaurants": [
      {
        title: "Restaurants",
        link: "/dashboard/restaurants",
      },
    ],
    "Delivery Partners": [
      {
        title: "Delivery Partners",
        link: "/dashboard/delivery-partners",
      },
    ],
    "Location": [
      {
        title: "Location",
        link: "/dashboard/place",
      },
    ],
    "Orders": [
      {
        title: "Orders",
        link: "/dashboard/orders",
      },
    ],
    "Manage Banners": [
      {
        title: "Manage Banners",
        link: "/dashboard/manage-banners",
      },
    ],
    "Payables": [
      {
        title: "Payables",
        link: "/dashboard/payables",
      },
    ],
    "Manage Notifications": [
      {
        title: "Manage Notifications",
        link: "/dashboard/manage-notifications",
      },
    ],
    "Sub-admin Role": [
      {
        title: "Sub-admin Role",
        link: "/dashboard/sub-admin-role",
      },
    ],
    "Promo": [
      {
        title: "Promo",
        link: "/dashboard/promo",
      },
    ],
    "Loyality": [
      {
        title: "Loyality",
        link: "/dashboard/loyality",
      },
    ],
    "Home Setting": [
      {
        title: "Home Setting",
        link: "/dashboard/home-settings",
      },
    ],
    "Customer Support": [
      {
        title: "Customer Support",
        link: "/dashboard/customer-support",
      },
    ],
  },
} as any;

export const headerSlice = createSlice({
  name: "header",
  initialState,
  reducers: {
    setHeaderState: (state, { payload }) => {
      
      Object.keys(payload).map((key) => {
        state[key] = payload[key];
      });
    },
  },
});
export const { setHeaderState } = headerSlice.actions;
export const selectHeader = (state: RootState) => state.header;

export default headerSlice.reducer;
