import { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { Layout, Menu } from "antd";
import { Link, useLocation } from "react-router-dom";

import DashboardIcon from "./images/sidebar-icons/icon1.svg";
import ManageCustomerIcon from "./images/sidebar-icons/icon2.svg";
import MasterCategoryIcon from "./images/sidebar-icons/icon3.svg";
import RestaurantsIcon from "./images/sidebar-icons/icon4.svg";
import DeliveryIcon from "./images/sidebar-icons/icon5.svg";
import LocationIcon from "./images/sidebar-icons/icon6.svg";
import OrdersIcon from "./images/sidebar-icons/icon7.svg";
import BannersIcon from "./images/sidebar-icons/icon7.svg";
import PayablesIcon from "./images/sidebar-icons/icon9.svg";
import NotificationIcon from "./images/sidebar-icons/icon10.svg";
import AdminIcon from "./images/sidebar-icons/icon11.svg";
import PromoIcon from "./images/sidebar-icons/icon12.svg";
import LoyalityIcon from "./images/sidebar-icons/icon13.svg";
import SettingsIcon from "./images/sidebar-icons/icon14.svg";
import SupportIcon from "./images/sidebar-icons/icon15.svg";
import { MdChevronLeft, MdChevronRight } from "react-icons/md";
import { useTranslation } from "react-i18next";
import logo from "./images/food_app_logo.svg";
import { useAppSelector } from "../global/hooks";
import { selectAuth } from "../global/authSlice";
const { Sider } = Layout;

export default function SideBar({ collapsed, click }: any) {
  const [key, setKey] = useState() as any;
  const [load, setLoad] = useState(false);

  useEffect(() => {
    load && document.getElementById("sidebar-" + key)?.scrollIntoView();
    setKey(pathname.split("/")[2]);
  }, [load]);

  const {
    user: { assigned_permission },
  } = useAppSelector(selectAuth);
  const { pathname } = useLocation();
  const { t } = useTranslation();

  useEffect(() => {
    setKey(pathname.split("/")[2]);
  }, [pathname]);


  return (
    <SideWrap collapse={collapsed}>
      {key && assigned_permission && (
        <Sider
          onLoad={() => setLoad(true)}
          collapsible
          collapsedWidth={70}
          trigger={null}
          collapsed={collapsed}
          width="270"
          className="h-screen w-60 sticky top-0 left-0"
          style={{
            boxShadow: "0 1px 4px -1px rgb(0 0 0 / 15%)",
            backgroundColor: "var(--acentColor)",
          }}
        >
          <div className="collapse" onClick={click}>
            {collapsed ? <MdChevronRight /> : <MdChevronLeft />}
          </div>

          <div className="logo">
            <img
              src={logo}
              alt="logo"
              style={{
                width: collapsed ? "25px" : "60px",
                transition: "width .3s",
              }}
            />
          </div>
          <br />
          <br />
          <br />
          <br />
          <div style={{ height: "13px" }} />
          <Menu
            className="menu AppSiderBar"
            onClick={({ key }) => setKey(key)}
            style={{ border: "none" }}
            theme="light"
            mode="inline"
            selectedKeys={[key]}
          >
            {assigned_permission["Dashboard"]?.includes("View") && (
              <Menu.Item
              title={t("sidebar.dashboard")}
                id="sidebar-home"
                key="home"
                className={`opacity-90 hover:opacity-100 ${
                  key === "home" ? "opacity-100" : ""
                }`}
                icon={
                  <img
                    alt=""
                    src={DashboardIcon}
                    className={`${
                      key === "home" ? "opacity-100" : "opacity-70"
                    }`}
                  />
                }
              >
                <Link to="/dashboard/home">{t("sidebar.dashboard")}</Link>
              </Menu.Item>
            )}
            {assigned_permission["Manage Customers"]?.includes("List") && (
              <Menu.Item
              title={t("sidebar.manage_customers")}
                id="sidebar-manage-customers"
                key="manage-customers"
                className={`opacity-90 hover:opacity-100 ${
                  key === "manage-customers" ? "opacity-100" : ""
                }`}
                icon={
                  <img
                    alt=""
                    src={ManageCustomerIcon}
                    className={`${
                      key === "manage-customers" ? "opacity-100" : "opacity-70"
                    }`}
                  />
                }
              >
                <Link to="/dashboard/manage-customers">
                  {t("sidebar.manage_customers")}
                </Link>
              </Menu.Item>
            )}

            <Menu.Item
            title={t("sidebar.master_category")}
              id="sidebar-master-category"
              key="master-category"
              className={`opacity-90 hover:opacity-100 ${
                key === "master-category" ? "opacity-100" : ""
              }`}
              icon={
                <img
                  alt=""
                  src={MasterCategoryIcon}
                  className={`${
                    key === "master-category" ? "opacity-100" : "opacity-70"
                  }`}
                />
              }
            >
              <Link to="/dashboard/master-category">
                {t("sidebar.master_category")}
              </Link>
            </Menu.Item>

            {assigned_permission["Restaurants"]?.includes("List") && (
              <Menu.Item
              title={t("sidebar.restaurants")}
                id="sidebar-restaurants"
                key="restaurants"
                className={`opacity-90 hover:opacity-100 ${
                  key === "restaurants" ? "opacity-100" : ""
                }`}
                icon={
                  <img
                    alt=""
                    src={RestaurantsIcon}
                    className={`${
                      key === "restaurants" ? "opacity-100" : "opacity-70"
                    }`}
                  />
                }
              >
                <Link to="/dashboard/restaurants">
                  {t("sidebar.restaurants")}
                </Link>
              </Menu.Item>
            )}

            {assigned_permission["Delivery Partners"]?.includes("List") && (
              <Menu.Item
              title={t("sidebar.delivery_partners")}
                id="sidebar-delivery-partners"
                key="delivery-partners"
                className={`opacity-90 hover:opacity-100 ${
                  key === "delivery-partners" ? "opacity-100" : ""
                }`}
                icon={
                  <img
                    alt=""
                    src={DeliveryIcon}
                    className={`${
                      key === "delivery-partners" ? "opacity-100" : "opacity-70"
                    }`}
                  />
                }
              >
                <Link to="/dashboard/delivery-partners">
                  {t("sidebar.delivery_partners")}
                </Link>
              </Menu.Item>
            )}

            <Menu.Item
            title={t("sidebar.location")}
              id="sidebar-place"
              key="place"
              className={`opacity-90 hover:opacity-100 ${
                key === "place" ? "opacity-100" : ""
              }`}
              icon={
                <img
                  alt=""
                  src={LocationIcon}
                  className={`${
                    key === "place" ? "opacity-100" : "opacity-70"
                  }`}
                />
              }
            >
              <Link to="/dashboard/place">
              {t("sidebar.location")}
              </Link>
            </Menu.Item>
            {assigned_permission["Orders"]?.includes("List") && (
              <Menu.Item
              title={t("sidebar.orders")}
                id="sidebar-orders"
                key="orders"
                className={`opacity-90 hover:opacity-100 ${
                  key === "orders" ? "opacity-100" : ""
                }`}
                icon={
                  <img
                    alt=""
                    src={OrdersIcon}
                    className={`${
                      key === "orders" ? "opacity-100" : "opacity-70"
                    }`}
                  />
                }
              >
                <Link to="/dashboard/orders">
                {t("sidebar.orders")}
                </Link>
              </Menu.Item>
            )}

            {assigned_permission["Manage Banners"]?.includes("List") && (
              <Menu.Item
              title={t("sidebar.manage_banners")}
                id="sidebar-manage-banners"
                key="manage-banners"
                className={`opacity-90 hover:opacity-100 ${
                  key === "manage-banners" ? "opacity-100" : ""
                }`}
                icon={
                  <img
                    alt=""
                    src={BannersIcon}
                    className={`${
                      key === "manage-banners" ? "opacity-100" : "opacity-70"
                    }`}
                  />
                }
              >
                <Link to="/dashboard/manage-banners">
                {t("sidebar.manage_banners")}
                </Link>
              </Menu.Item>
            )}

            {assigned_permission["Payables"]?.includes("List") && (
              <Menu.Item
              title={t("sidebar.payables")}
                id="sidebar-payables"
                key="payables"
                className={`opacity-90 hover:opacity-100 ${
                  key === "payables" ? "opacity-100" : ""
                }`}
                icon={
                  <img
                    alt=""
                    src={PayablesIcon}
                    className={`${
                      key === "payables" ? "opacity-100" : "opacity-70"
                    }`}
                  />
                }
              >
                <Link to="/dashboard/payables">
                {t("sidebar.payables")}
                </Link>
              </Menu.Item>
            )}

            {assigned_permission["Manage Notifications"]?.includes("List") && (
              <Menu.Item
              title={t("sidebar.manage_notifications")}
                id="sidebar-manage-notifications"
                key="manage-notifications"
                className={`opacity-90 hover:opacity-100 ${
                  key === "manage-notifications" ? "opacity-100" : ""
                }`}
                icon={
                  <img
                    alt=""
                    src={NotificationIcon}
                    className={`${
                      key === "manage-notifications"
                        ? "opacity-100"
                        : "opacity-70"
                    }`}
                  />
                }
              >
                <Link to="/dashboard/manage-notifications">
                  {t("sidebar.manage_notifications")}
                </Link>
              </Menu.Item>
            )}

            {assigned_permission["Sub Admin Role"]?.includes("List") && (
              <Menu.Item
              title={t("sidebar.sub_admin_role")}
                id="sidebar-sub-admin-role"
                key="sub-admin-role"
                className={`opacity-90 hover:opacity-100 ${
                  key === "sub-admin-role" ? "opacity-100" : ""
                }`}
                icon={
                  <img
                    alt=""
                    src={AdminIcon}
                    className={`${
                      key === "sub-admin-role"
                        ? "opacity-100"
                        : "opacity-70"
                    }`}
                  />
                }
              >
                <Link to="/dashboard/sub-admin-role">
                  {t("sidebar.sub_admin_role")}
                </Link>
              </Menu.Item>
            )}

            {assigned_permission["Promo"]?.includes("List") && (
              <Menu.Item
              title={t("sidebar.promo")}
                id="sidebar-promo"
                key="promo"
                className={`opacity-90 hover:opacity-100 ${
                  key === "promo" ? "opacity-100" : ""
                }`}
                icon={
                  <img
                    alt=""
                    src={PromoIcon}
                    className={`${
                      key === "promo" ? "opacity-100" : "opacity-70"
                    }`}
                  />
                }
              >
                <Link to="/dashboard/promo">
                  {t("sidebar.promo")}
                </Link>
              </Menu.Item>
            )}

            <Menu.Item
            title={t("sidebar.loyality")}
              id="sidebar-loyality"
              key="loyality"
              className={`opacity-90 hover:opacity-100 ${
                key === "loyality" ? "opacity-100" : ""
              }`}
              icon={
                <img
                  alt=""
                  src={LoyalityIcon}
                  className={`${
                    key === "loyality" ? "opacity-100" : "opacity-70"
                  }`}
                />
              }
            >
              <Link to="/dashboard/loyality">
                {t("sidebar.loyality")}
              </Link>
            </Menu.Item>

            <Menu.Item
            title={t("sidebar.home_setting")}
              id="sidebar-home-settings"
              key="home-settings"
              className={`opacity-90 hover:opacity-100 ${
                key === "home-settings" ? "opacity-100" : ""
              }`}
              icon={
                <img
                  alt=""
                  src={SettingsIcon}
                  className={`${
                    key === "home-settings" ? "opacity-100" : "opacity-70"
                  }`}
                />
              }
            >
              <Link to="/dashboard/home-settings">
                {t("sidebar.home_setting")}
              </Link>
            </Menu.Item>
            {assigned_permission["Support"]?.includes("View") && (
              <Menu.Item
              title={t("sidebar.customer_support")}
                id="sidebar-customer-support"
                key="customer-support"
                className={`opacity-90 hover:opacity-100 ${
                  key === "customer-support" ? "opacity-100" : ""
                }`}
                icon={
                  <img
                    alt=""
                    src={SupportIcon}
                    className={`${
                      key === "customer-support" ? "opacity-100" : "opacity-70"
                    }`}
                  />
                }
              >
                <Link to="/dashboard/customer-support">
                  {t("sidebar.customer_support")}
                </Link>
              </Menu.Item>
            )}
          </Menu>
        </Sider>
      )}
    </SideWrap>
  );
}

const SideWrap = styled.div<{ collapse: any }>`
  z-index: 3;
  .logo {
    position: absolute;
    top: ${(props) => (props.collapse ? "6%" : "4%")};
    left: ${(props) => (props.collapse ? "32%" : "20%")};
  }
  .menu {
    img {
      padding-right: ${(props) => (props.collapse ? "0" : "1rem")};
    }
  }

  .ant-menu-item-selected {
    background-color: var(--accentColor);
    box-shadow: 0px 1px 4px rgb(0 0 0 / 2%) !important;
    font-weight: 600;
  }

  .ant-menu {
    overflow-x: hidden !important;
    height: 85vh;
    &::-webkit-scrollbar {
      display: none;
    }
  }

  .ant-menu-item {
    display: flex;
    align-items: center;
    border-radius: 19.5px 0px 0px 19.5px;
    transform: ${(props) =>
      props.collapse ? "translateX(5px)" : "translateX(30px)"};
    width: ${(props) => (props.collapse ? "91%" : "89%")};
    margin: 0.5rem 0 !important;

    a {
      font-size: 13px;
      font-family: "Montserrat", sans-serif !important;
      letter-spacing: 0.2px;
    }
    svg {
      transform: translateY(-1px);
    }
    span {
      font-size: 1rem;
    }
  }

  .collapse {
    padding: 4px;
    border-radius: 50%;
    cursor: pointer;
    background-color: var(--acentColor);
    position: absolute;
    right: -15.5px;
    z-index: 25;
    top: 7%;
    color: white;
    font-size: 1.6rem;
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.25);
  }
`;
