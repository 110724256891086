import { useCallback, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { Radio } from "antd";
import { Link } from "react-router-dom";
import noImage from "./images/profile.jpg";
import { IoIosArrowDown } from "react-icons/io";
import { motion, AnimatePresence } from "framer-motion";
import {
  selectAuth,
  getLng,
  setPermissions,
  setUser,
} from "../global/authSlice";
import { useAppDispatch, useAppSelector } from "../global/hooks";
import { useTranslation } from "react-i18next";
import { useFetchOne } from "../api/useApiHook";
import { selectHeader, setHeaderState } from "../global/headerSlice";
import key from "../key";
import { handleLogout } from "./shared/customValidators";

export default function HeaderSec() {
  const [profileMenu, openProfileMenu] = useState({ main: false, sub: false });
  const resetState = () => openProfileMenu({ main: false, sub: false });
  const headerDetails = useAppSelector(selectHeader);
  const dispatch = useAppDispatch();
  const tg = useRef() as any;
  const { lng, user } = useAppSelector(selectAuth);
  const { i18n } = useTranslation();

  const outerClickHandler = useCallback((ev) => {
    tg.current && !tg.current.contains(ev.target) && resetState();
  }, []);

  useEffect(() => {
    if (profileMenu.main) {
      document.addEventListener("click", outerClickHandler);
    }
    return () => document.removeEventListener("click", outerClickHandler);
  }, [outerClickHandler, profileMenu.main]);

  const handleLng = (e: any) => {
    dispatch(getLng(e.target.value));
    i18n.changeLanguage(e.target.value);
  };

  const { data, isLoading } = useFetchOne({
    url: "profile-details",
  });

  useEffect(() => {
    if (data) {
      dispatch(setPermissions(data?.assigned_permission));
      delete data?.assigned_permission;
      dispatch(setUser(data));
      dispatch(setHeaderState({ loading: false }));
    }
  }, [data, dispatch]);

  return !isLoading ? (
    <HeaderWrap>
      <div className="flex justify-end px-5 h-16 bg-white">
        <p className="text-xl font-bold w-full pt-5 pl-5">
          {headerDetails?.title}
        </p>
        <div className="col-span-2 grid items-center justify-end">
          <div
            className="flex justify-center items-center gap-x-2 px-2"
            onClick={() =>
              openProfileMenu({ ...profileMenu, main: !profileMenu.main })
            }
          >
            <div className="rounded-full w-10 h-10 grid items-center overflow-hidden">
              <img
                alt=""
                src={
                  user?.profile_pic
                    ? key.BACKEND_FILE_URL + user?.profile_pic
                    : noImage
                }
                className="h-full w-full object-cover cursor-pointer"
                style={{ boxShadow: "0px 0px 3px gray" }}
              />
            </div>
            <IoIosArrowDown
              className=" cursor-pointer"
              style={profileMenu.main ? { transform: "rotate(180deg)" } : {}}
            />
          </div>
        </div>
      </div>
      {profileMenu.main && (
        <AnimatePresence>
          <ProfileMenu
            ref={tg}
            className="shadow-2xl p-2 text-sm"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.36, type: "tween", ease: "easeInOut" }}
          >
            <Link
              to="/dashboard/profile"
              className="text-black hover:text-black"
              onClick={() => resetState()}
            >
              <p className="item">Profile Information</p>
            </Link>
            <Link
              to="/dashboard/change-password"
              className="text-black hover:text-black"
              onClick={() => resetState()}
            >
              <p className="item">Change Password</p>
            </Link>
            <Link
              to="/dashboard/food911details"
              className="text-black hover:text-black"
              onClick={() => resetState()}
            >
              <p className="item">Food 9-11 Details</p>
            </Link>
            <div className="items">
              <div
                className="shower flex justify-between items-center"
                onClick={() =>
                  openProfileMenu({ ...profileMenu, sub: !profileMenu.sub })
                }
              >
                <p style={{ fontSize: "12px", fontWeight: 600 }}>Language</p>
                <p
                  style={profileMenu.sub ? { transform: "rotate(180deg)" } : {}}
                >
                  <IoIosArrowDown className=" cursor-pointer " />
                </p>
              </div>
              {profileMenu.sub && (
                <Radio.Group
                  className="grid font-medium"
                  onChange={handleLng}
                  value={lng}
                >
                  <Radio value="en">English</Radio>
                  <Radio value="ru">Russian</Radio>
                  <Radio value="lt">Lithuanian</Radio>
                </Radio.Group>
              )}
            </div>
            <div className="item text-red-500" onClick={handleLogout}>
              Logout
            </div>
          </ProfileMenu>
        </AnimatePresence>
      )}
    </HeaderWrap>
  ) : (
    <p></p>
  );
}

const HeaderWrap = styled.div`
  .ant-input-affix-wrapper {
    width: auto;
    border-radius: 18px;
    .ant-input-prefix {
      padding-right: 10px;
    }
  }
`;

const ProfileMenu = styled(motion.div)`
  cursor: pointer;
  background: #ffffff;
  overflow: hidden;
  min-width: 201px;
  border-radius: 11px;
  position: absolute;
  top: 63px;
  right: 47px;
  z-index: 3;
  display: grid;
  gap: 2px;
  .item {
    width: 100%;
    padding: 5px 8px;
    font-size: 12px;
    line-height: 22px;
    font-weight: 600;
  }
  .items {
    padding: 5px 8px;
  }
  .ant-radio-group {
    display: grid;
    gap: 2px;
    padding-top: 7px;
  }
`;
