import moment from "moment";

export const isNumberKey = (evt: any, isMobile = false, location = false) => {
  let value = evt.target.value;
  let allowed = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"];
  if(isMobile){
    allowed.push("+")
  }
  if (location && value.indexOf("-") < 0) {
    allowed.push("-");
  }
  if (value !== "" && !isMobile && value.indexOf(".") < 0) {
    allowed.push(".");
  }
  if (!allowed.includes(evt.key)) {
    evt.preventDefault();
  }
};

export const disableDate = (current: any) => current && current < moment().subtract(1, "days");

// export function isPermission(arr1: string[], arr2: string[]): boolean {
//   if (!arr1) {
//     return false;
//   }
//   let tmp = [];
//   arr1.map((a) => {
//     arr2.includes(a) && tmp.push(a);
//   });
//   return tmp.length === arr2.length;
// }

export function handleLogout() {
  localStorage.clear();
  window.location.reload();
}
