import { Layout } from 'antd'
import styled from 'styled-components'
import logo from "../../images/logo1.png";
import useDocumentTitle from '../../shared/useDocument';
export default function TermsOfServices(): any {
    useDocumentTitle('TermsOfServices')
    return <div className="flex flex-col h-screen w-screen overflow-x-hidden">
        <Wrapper>
            <Layout.Header
                className='flex items-center gap-x-4'
            >
                <div className="p-2">
                    <img src={logo} alt={logo} className="h-full bg-black relative right-7" />
                </div>
                <p className='text-3xl sm:text-base sm:font-extrabold font-bold'>User Terms of Service</p>
            </Layout.Header>
            <hr />
        </Wrapper>
        <div className='container grid gap-y-4 p-5 text-lg'>
            <div className='grid gap-y-2 p-2'>

                <p>
                    By using the Food911 Service, you agree to be bound by these User Terms of Service <b>(“Terms of Service”)</b>.
                </p>
                <p>
                    Please read the following Terms of Service carefully before using the Food911 Service. If you do not agree with the Terms of Service, you cannot use the Food911 Service. These Terms of Service apply to any use of the Food911 Service and to the Purchase
                    Agreements made hereunder. A more detailed description of the Food911 Service and information on system requirements is available at Food911.com.
                </p>
                <p>
                    If you have a Food911 at Work Account, these Terms of Service also apply to our customer organization which has granted you the Food911 at Work Feature <b>(“Customer Organization”)</b> with respect to Orders made by you through the Food911 at Work Feature. If
                    there is any discrepancy between these Terms of Service and the Food911 Corporate Terms & Conditions applicable to the Customer Organization, the Food911 Corporate Terms & Conditions shall, however, prevail.
                </p>
                <p>
                    The Terms of Service are concluded between us and the User only, and not with Apple Inc., and Apple Inc. is not responsible for the Food911 App or the content thereof. You agree that Apple Inc., and Apple Inc.’s subsidiaries <b>(“Apple”)</b>, are third party
                    beneficiaries of the Terms of Service, and that, upon your acceptance of the Terms of Service, Apple will have the right (and will be deemed to have accepted the right) to enforce the Terms of Service against you as a third party beneficiary
                    thereof.
                </p>
                <ul>
                    <li className='p-2 grid gap-y-2'>
                        <p className="font-semibold text-xl">1. Definitions</p>
                        <div className="p-2">
                            <p>
                                <b>“Partner”</b> means a restaurant, retail shop or other service partner who has signed a partner agreement with Food911, or their substitute, and who, as a trader, offers its products and possible delivery or other services for sale through the Food911 Service.
                            </p>
                            <p>
                                <b>“Purchase Agreement”</b> means an agreement for the purchase of Partner’s products and possible delivery or other services made on an Order. With respect to Orders placed through the Food911 at Work Feature, the Purchase Agreement forms a binding agreement
                                between the Partner and the Customer Organization. With respect to Orders placed through any other payment method of the User, the Purchase Agreement forms a binding agreement between Partner and the User.
                            </p>
                            <p><b>“Food911” means:</b></p>
                            <p>
                                <b>Food911 Enterprises Oy</b>, Arkadiankatu 6, 00100 Helsinki, Finland, if the User is located in Finland,
                            </p>
                            <p>
                                <b>Food911 Česko s.r.o.</b>, Na poříčí 1047/26, Nové Město, 110 00 Prague 1, Czech Republic, if the User is located in the Czech Republic,
                            </p>
                            <p>
                                <b>Food911 Polska sp. z o.o.</b>, ul. Koszykowa 54, piętro 00-675 Warsaw, Poland, if the User is located in Poland,
                            </p>
                            <p>
                                <b>Food911 Enterprises Israel Ltd.</b>, Allenby St. 113, 6581706 Tel Aviv, Israel, if the User is located in Israel,
                            </p>
                            <p><b>Food911 Technologies Greece Provision of Food Services S.A.</b>, Leoforos Vouliagmenis 26, 11743 Athens, Greece, if the User is located in Greece,

                            </p>
                            <p><b>Food911 d.o.o. Beograd-Stari Grad</b>, Bulevar vojvode Bojovića 8, 11000 Belgrade, Serbia, if the User is located in Serbia,

                            </p>
                            <p> <b>Food911 Azerbaijan LLC</b>, Yasamal district, Hyatt Tower 2, 4th floor, 8 Izmir Street, AZ1065 Baku, Azerbaijan, if the User is located in Azerbaijan,

                            </p>
                            <p><b>Food911 Technologies Kazakhstan LLP</b>, Park View, Kunaev St 77, 050000, Almaty, Kazakhstan, if the User is located in Kazakhstan,

                            </p>
                            <p><b>Food911 Slovensko s. r. o.</b>, Palisády 36, 811 06 Bratislava – Staré Mesto, Slovak Republic, registered in the Commercial register of the District Court Bratislava I, Section: Sro, Insert No.: 137637/B, if the User is located in Slovakia,</p>
                            <p><b>Food911 Malta Limited</b>, 171, Old Bakery Street, Valletta, VLT 1455, Malta, if the User is located in Malta,</p>
                            <p><b>Food911 Norway AS</b>, Øvre Slottsgate 4, 0157 Oslo, Norway, if the User is located in Norway,</p>
                            <p><b>Food911 Latvija SIA</b>, Elizabete iela 45/47, Riga, Latvia, if the User is located in Latvia,</p>
                            <p><b>UAB Food911 LT</b>, Jasinskio g. 14a-57, LT-01135, Vilnius, Lithuania, if the User is located in Lithuania,</p>
                            <p><b>Food911 Japan K.K.</b>, Portal Point Shibuya 7F, 11-3, Jinnan 1-chome, Shibuya-ku, Tokyo, if the User is located in Japan,</p>
                            <p><b>Food911 Georgia LLC</b>, 49d Chavchavadze Ave 179, Tbilisi, Georgia, if the User is located in Georgia,</p>
                            <p><b>Food911 Eesti OÜ</b>, Aia 10 a, 10111, Tallinn, Estonia, if User is located in Estonia,</p>
                            <p><b>Food911 Zagreb d.o.o.</b>, Metalčeva ul. 5, 1st floor, 10000 Zagreb, Croatia, if the User is located in Croatia,</p>
                            <p><b>Food911 Danmark ApS</b>, Borgergade 10, 2 sal., 1300 Copenhagen K, Denmark, if User is located in Denmark.</p>
                            <p><b>Food911 Sverige AB</b>, Celsiusgatan 10, 11230, Stockholm, Sweden, if the user is located in Sweden.</p>
                            <p><b>Food911 Cyprus Limited</b>, Stasikratous 35 3rd floor, office 302, 1065, Nicosia, Cyprus if the User is located in Cyprus.</p>
                            <p>
                                <b>Food911 Magyarország Kft</b>, Salétrom utca 4. földszint, 1085, Budapest, Hungary, if the User is located in Hungary.
                            </p>
                            <p><b>Food911, tehnologije d.o.o. Ljubljana</b>, Parmova ulica 53, 1000, Ljubljana, Slovenia, if the User is located in Slovenia.</p>
                            <p><b>“Food911 App”</b> means a digital application called Food911 provided by Food911 for natural and legal persons to order products from Food911’s Partners.</p>
                            <p><b>“Food911 at Work Account”</b> means a user account in the Food911 App onto which the Food911 at Work Feature has been added.</p>
                            <p><b>“Food911 at Work Feature”</b> means the payment method and feature of the Food911 App through which the Customer Organization’s authorized Users can make Orders at the Customer Organization’s cost.</p>
                            <p><b>“Food911 Service”</b> means both Food911 App and Food911 website Food911.com and other additional locations as mentioned at Food911.com.</p>
                            <p><b>“User”</b> or “you” means a natural person using the Food911 Service. The User must be of the age of 18 or older.</p>
                        </div>

                    </li>
                    <li className='p-2 grid gap-y-2'>
                        <p className="font-semibold text-xl">2. Identity of Food911</p>
                        <div className="p-2">
                            <p><b>Food911 Enterprises Oy</b>, Arkadiankatu 6, 00100 Helsinki, Finland, business ID 2646674-9, VAT ID FI26466749, if the User is located in Finland,</p>
                            <p><b>Food911 Česko s.r.o.</b>, Na poříčí 1047/26, Nové Město, 110 00 Prague 1, Czech Republic, business ID (IČO): 07030550, VAT ID (DIČ): CZ07030550, if the User is located in the Czech Republic,</p>
                            <p><b>Food911 Polska sp. z o.o.</b>, ul. Koszykowa 54, piętro 00-675 Warsaw, Poland, entered into the register of entrepreneurs of the National Court Register, kept by District Court for the capital city of Warsaw in Warsaw, XII Business Department
                                of the National Court Register under KRS No. 0000745637, tax identification number NIP 7010846311, if the User is located in Poland,</p>
                            <p><b>Food911 Enterprises Israel Ltd.</b>, Allenby St. 113, 6581706 Tel Aviv, Israel, company no. 51-588715-6, VAT ID 515887156, if the User is located in Israel,</p>
                            <p>
                                <b>Food911 Technologies Greece Provision of Food Services S.A.</b>, Leoforos Vouliagmenis 26, 11743 Athens, Greece, business ID 147811001000, VAT ID EL801047822, if the User is located in Greece,
                            </p>
                            <p><b>Food911 d.o.o. Beograd-Stari Grad</b>, Nušićeva 14, 11000 Belgrade, Serbia, business ID 21408930, VAT ID 110976122, if the User is located in Serbia,</p>
                            <p><b>Food911 Azerbaijan LLC</b>, Yasamal district, Hyatt Tower 2, 4th floor, 8 Izmir Street, AZ1065 Baku, Azerbaijan, tax payer identification number 1404631321, VAT ID 1404631321, if the User is located in Azerbaijan*,</p>
                            <p><b>Food911 Technologies Kazakhstan LLP</b>, Park View, Kunaev St 77, 050000, Almaty, Kazakhstan, business ID 190540026305, if the User is located in Kazakhstan*,</p>
                            <p>
                                <b>Food911 Slovensko s. r. o.</b>, Palisády 36, 811 06 Bratislava – Staré Mesto, Slovak Republic, business ID 52 396 771, registered in the Commercial register of the District Court Bratislava I, Section: Sro, Insert No.: 137637/B, VAT ID (IČ DPH): SK 2121036093,
                                Tax ID (DIČ): 2121036093, if the User is located in Slovakia,
                            </p>
                            <p>
                                <b>Food911 Malta Limited</b>, 171, Old Bakery Street, Valletta, VLT 1455, Malta, business ID C94616, if the User is located in Malta,
                            </p>
                            <p><b>Food911 Norway AS</b>, Øvre Slottsgate 4, 0157 Oslo, Norway, business ID: 920464254, VAT ID: 920 464 254 MVA, if the User is located in Norway,</p>
                            <p><b>Food911 Latvija SIA</b>, Elizabete iela 45/47, Riga, Latvia, business ID: 40203027461, VAT ID: LV40203027461, if the User is located in Latvia,</p>
                            <p><b>UAB Food911 LT</b>, Jasinskio g. 14a-57, 01135 Vilnus, Lithuania, business ID: 304573947, VAT ID: LT100011227411, if the User is located in Lithuania,</p>
                            <p><b>Food911 Japan K.K.</b>, Portal Point Shibuya 7F, 11-3, Jinnan 1-chome, Shibuya-ku, Tokyo, Japan, business ID: 40100-01-204751, VAT ID: 4010001204751, if the User is located in Japan,</p>
                            <p><b>Food911 Georgia LLC</b>, 49d Chavchavadze Ave, 179 Tbilisi, Georgia, business ID 405260042 and VAT ID: 405260042, if the User is located in Georgia,</p>
                            <p><b>Food911 Eesti OÜ</b>, Aia 10 a, 10111 Tallinn, Estonia, business ID: 8273006000, VAT ID: 94112614, if User is located in Estonia,</p>
                            <p><b>Food911 Zagreb d.o.o.</b>, Metalčeva ul. 5, 1st floor, 10000 Zagreb, Croatia, OIB: 25531986377, VAT ID: HR25531986377, if the User is located in Croatia,</p>
                            <p><b>Food911 Danmark ApS</b>, Borgergade 10, 2 sal., 1300 Copenhagen K, Denmark, business ID: 37540447, VAT ID: DK37540447, if the User is located in Denmark,</p>
                            <p><b>Food911 Sverige AB</b>, Celsiusgatan 10, 11230, Stockholm, Sweden, business ID: 559049-2335, VAT ID: SE559049233501, if the user is located in Sweden,</p>
                            <p><b>Food911 Cyprus Limited</b>, Stasikratous 35 3rd floor, office 302, 1065, Nicosia, Cyprus, business ID: HE 404490, VAT ID: CY10404490R, if the User is located in Cyprus,</p>
                            <p><b>Food911 Magyarország Kft</b>, Salétrom utca 4. földszint, 1085, Budapest, Hungary, business ID: 01-09-322693, VAT ID: HU26296702, if the User is located in Hungary,</p>
                            <p><b>Food911, tehnologije d.o.o. Ljubljana</b>, Parmova ulica 53, 1000, Ljubljana, Slovenia, business ID: 8273006000, VAT ID: 94112614, if the User is located in Slovenia.</p>
                            <p><b>Trade name:</b> “Food911",</p>
                            <p><b>Email address:</b> support@Food911.com.</p>
                            <p>*Please note that while the Food911 Service is legally provided to you by your local Food911 entity in these countries, the payment service is arranged by Food911 Enterprises Oy through a third-party payment service provider. This
                                also means that Food911 Enterprises Oy is responsible for possible refunds, complaints and other claims related to your transactions in the Food911 Service. You may thus contact Food911 Enterprises Oy directly at the contact details
                                provided above in case of any dispute or claim related to the Food911 Service.</p>
                            <p>Information about the identity and products and services of the Partners are provided in the Food911 Service.</p>

                        </div>

                    </li>
                    <li className='p-2 grid gap-y-2'>
                        <p className="font-semibold text-xl">3. Description of the Food911 Service</p>
                        <div className="p-2">
                            <p><b>3.1.</b> Food911 provides a platform on which the User can purchase food and other products or services from the Partner of their choice as well as possible delivery services either from the Partner or Food911, as applicable. In certain
                                countries Partners charge Users a delivery fee and service fee for the delivery services as defined in more detail in the Food911 Service where relevant.</p>
                            <p>
                                <b>3.2.</b> The Partner provides the information about their products and possible services in the Food911 Service, including but not limited to information on product characteristics, allergens and instructions of use. If you have allergies or other dietary
                                restrictions or for other reason wish to receive more detailed information about the products, please contact the relevant Partner. You can also contact Food911's customer support who can contact the Partner on your behalf for
                                such enquiries. The Partner is however responsible for providing accurate, up-to-date and legally required information about the products, including providing information on applicable prices and indicating the price per unit where
                                required under applicable laws. Please note that the sale and purchase of the Partner’s products and possible services may be subject to additional terms and conditions of the Partner or Food911 on a case-by-case basis as set out
                                in the Food911 Service.
                            </p>
                            <p><b>3.3.</b> When selecting the Partner’s products and services that the User wishes to purchase from the Partner, the User makes a binding order to purchase the products and services from the Partner on the terms and conditions presented
                                to the User herein and in the Food911 Service before placing the order <b>(“the Order”)</b>. After having received the Order, Food911 will transmit the details of the Order to the Partner. When the Order is accepted by the Partner and
                                Food911 has provided the User with an order confirmation on behalf of the Partner, the User or the Customer Organization and the Partner enter into the Purchase Agreement for the purchase of the Partner's products and, if applicable,
                                delivery or other services. In some cases, the Purchase Agreement for the purchase of the delivery service is entered into with Food911 instead of the Partner, as reflected on the receipt. Food911 will provide the User with a receipt
                                on behalf of the Partner, unless regulation in the country where the User is located requires that the Partner provides the receipt directly to the User, for the products and, to the extent applicable, for the delivery services.</p>
                            <p><b>3.4.</b> The Partner selected by the User will prepare and/or collect and pack the products set out on the Order. Food911 is not responsible or liable towards the User for the proper execution of the Purchase Agreement by the Partner.
                                Food911 is liable for the execution of the delivery services ordered by User from the Partner or Food911 on the Order as set out in these Terms of Service.</p>
                            <p>
                                <b>3.5.</b> When you use the Food911 Service, there may be a minimum value for purchase required, in which case you will be notified thereof before you make the Order on the Food911 Service. Where an Order fails to meet the minimum required value, you will have
                                the possibility to pay the difference to meet the minimum value or to add more products to your Order. The Partner determines in its sole discretion the selection and pricing of their products available on the Food911 Service.
                                The Partner may also impose certain restrictions on your Order, such as quantity limits and size limitations of the Order. The Partner is responsible for informing you of any limitation relating to the availability of any products.
                                User accepts that in case a certain product is temporarily unavailable, the Partner will fulfil the remaining part of your Order without including the unavailable product in the Order. You will not be charged for products that
                                are unavailable at the time of placing the Order.
                            </p>
                            <p>
                                <p><b>3.6.</b>  <i>For Users located in Israel:</i> </p>
                                <p><b>“Purchase Agreement”</b> means either one of the following binding agreements, as applicable</p>
                                <div className="pl-8 py-2">
                                    <ul className='list-disc '>
                                        <li>for the purchase of Partner’s products and possible delivery services made by an Order placed by a User, no matter any payment method
                                            of the User, or </li>
                                        <li>with respect to a Customer Organization, with respect to Orders placed through the Food911 at Work Feature. Food911 is a marketplace that facilitates transactions and delivery services by providing a platform
                                            on which the User can purchase food products and/or other products and delivery services in relation to food products and/or other products of certain Partners. When you purchase food products and/or other products from a Partner</li>
                                    </ul>
                                </div>
                                <p> Food911 facilitates the fulfillment of the purchase on the Food911 Service.</p>
                                <p>Please note and take into consideration that any and all information displayed through the Food911 Service is displayed AS – IS by Food911 as received by it from the Partner and is under the complete and sole responsibility of
                                    the Partner. So, If you have allergies or any other dietary restrictions or if you have any question or concern with regard to any matter related to products sold and offered by the Partner, please contact the relevant Partner
                                    which will provide you with the specific information upon request.</p>
                                <p>When the User wishes to purchase through the use of the Food911 Service and the Order is accepted by the Partner and Food911 has provided the User with an order confirmation on behalf of the Partner, the User or the Customer Organization,
                                    as the case may be, shall be considered for all purposes to have entered into the Purchase Agreement. Food911 will provide the User with a receipt that will include payment for the products ordered from the Partner and payment
                                    for the delivery services.</p>
                                <p>Food911 shall only be responsible for the transfer of the fees made by the User to the respective Partner and shall not, under any circumstances, be liable or responsible towards the User for the proper execution of the Order by
                                    the Partner. Both the User and the Partner each acknowledge and confirm that the Partner is solely liable for any defects in the contents and preparation of the Order or other shortcomings in the performance of the Order.</p>

                            </p>
                        </div>

                    </li>
                    <li className='p-2 grid gap-y-2'>
                        <p className="font-semibold text-xl">4. Right of revocation</p>
                        <div className="p-2">

                            <p><b>4.1.</b> You cannot withdraw from or cancel an Order for products or services once you have placed it, unless explicitly provided otherwise in these Terms of Service. Prior to placing an Order for a product or service you should carefully
                                review your selection. For Users located in Azerbaijan: placing the Order also includes the User's consent to launch the performance of the relevant agreement(s) stipulated herein.</p>
                            <p><b>4.2.</b> If you are a consumer, you are entitled to a right of revocation in accordance with the statutory provisions of the country where you are located. Consumer in the sense of these Terms of Service means any natural person who uses
                                the Food911 Service and places Orders for a purpose which predominantly cannot be attributed to their commercial or their self-employed professional acti</p>
                            <p>
                                <b>4.3.</b> Food911 follows the limitations of consumers' statutory cancellation rights as set out in applicable laws, according to which the right of revocation shall not exist for Purchase Agreements concerning certain items, including, but not limited to,
                                the follow

                                <div className="pl-8 py-2">
                                    <ul className='list-disc'>
                                        <li>products such as foodstuffs which are perishable or which may quickly pass their expiration date,</li>
                                        <li>products made to your specifications or clearly personalized,products made to your specifications or clearly personalized,</li>
                                        <li>sealed products which are not suitable for return due to health protection or hygiene reasons, if such products were unsealed after the delivery,</li>
                                        <li>products which, according to their nature, are inseparably mixed, after delivery, with other items,</li>
                                        <li>sealed games, movies or computer software which were unsealed after delivery.</li>
                                    </ul>
                                </div>
                            </p>
                            <p>
                                <b>4.4.</b> You have the right to revoke the Purchase Agreement of products, for which the right of revocation exists, within 14 days from the day of delivery of the products on the Order without giving a reason for the cancellation. In order to exercise your
                                right of revocation, you must inform Food911 of your decision to cancel by means of a clear statement (e.g., an email or a message sent through Food911’s in-app customer service chat) within the said time period of 14 days. The
                                Partner as the trader of the products on your Order has authorized Food911 to receive the revocation notification on the Partner’s behalf. In case the Partner has chosen to offer a longer cancellation period than 14 days, such
                                a period will apply to Orders made through the Food911 Service if explicitly mentioned in the descriptive details for the Partner on the Food911 Service. If you wish, you can use the below model revocation form for making the revocation
                                notification by filling in the information in the form and sending the information to Food911, which information shall also be forwarded by Food911 to the respective Partner:
                                <div className="italic">
                                    <p>Model revocation form</p>
                                    <p>To: [insert the name of the Partner, the Partner’s address and the Partner’s email address]:</p>
                                    <p>Herewith I/we (*) revoke the agreement concluded with me/us (*) regarding the purchase of the following goods (*)/ the provision of the following services (*)</p>
                                    <p> Ordered on (*)/received on (*)</p>
                                    <p>  Name of the consumer(s)</p>
                                    <p>Address of the consumer(s)</p>
                                    <p> Signature(s) of the consumer(s) (only in case of notification on paper)</p>
                                    <p> Date</p>
                                    <p>(*) Delete as applicable.</p>
                                </div>

                            </p>
                            <p>
                                <b>4.5.</b> If you revoke the Purchase Agreement, Food911 will refund to you all payments we have received from you, including the delivery fee (and service fee where applicable) no later than 14 days from the date on which we received notice of your revocation
                                of the Purchase Agreement or a part thereof. Note that the delivery fee (and service fee where applicable) will not be returned in case of only a partial revocation of your Order.
                            </p>
                            <p><b>4.6.</b> For making the refund, we will use the same means of payment as you used for the original purchase of your Order, unless expressly agreed otherwise with you. Note that if you paid for the Order with Food911 credits, we shall refund
                                you in Food911 credits. Food911 may refuse a refund until the Partner has received the products back or until you have provided proof that you have returned the products, whichever is earlier.</p>
                            <p><b>4.7.</b> You must return or send the products to the same Partner in the same location from which you made the Order. The contact details of the Partner’s point of sale are available on the Food911 Service. You have to return the products
                                to the Partner as soon as possible and in any case no later than 14 days after the day on which you notified us of the revocation of the Purchase Agreement. This period is deemed to have been observed if you dispatch the products
                                before the expiry of the period of 14 days.</p>
                            <p><b>4.8.</b> You shall bear the direct costs of returning the products. The returned products have to be in an unused condition and capable of being resold. You shall be liable for any loss in value of the products if such loss in value is
                                due to handling of the products that is not necessary for testing their condition, properties and functionality.</p>
                            <p><b>4.9.</b> Sometimes it may be possible that some of the Partner’s products that you wish to purchase through the Food911 Service are temporarily out of stock. For such cases and to the extent the Partner has enabled substitutions, you may
                                choose, prior to confirming your Order, whether you want to allow the Partner to substitute a missing item with a similar item. If you have allowed substitutions for a certain or all items in your Order, the Partner will substitute
                                such missing items in accordance with the following principles: (i) the substitute will be chosen based on what the Partner considers to be the most appropriate substitute so that it corresponds as much as possible to the quantity,
                                quality and price of the original item in the Order; (ii) substances typically causing allergies shall be carefully taken into account so that for example a lactose-free item is only substituted with a lactose-free item and a gluten-free
                                item is only substituted with a gluten-free item; (iii) diet or light drink is substituted with a diet or light drink; (iv) organic is substituted with organic; and (v) domestically produced is substituted with domestically produced.
                                You will not be charged for any additional fees or costs for a substituted item compared to the price of the original item in your Order. Should the price of the substitute be lower than the price of the original item in your Order,
                                you will be charged the price of the substitute it</p>
                        </div>
                    </li>
                    <li className='p-2 grid gap-y-2'>
                        <p className="font-semibold text-xl">
                            5. User Accounts
                        </p>
                        <p><b>5.1.</b> You have a limited, non-exclusive, non-transferable, and non-sublicensable right to use the Food911 Service in the form offered to you by us from time to time solely for the purposes set out herein. The license granted to the
                            User for the iOS Food911 App downloaded from Apple’s App Store is further limited to a license to use the Food911 App on any Apple-branded products that the User owns or controls and as permitted by the Usage Rules set forth in
                            the App Store Terms of Service and these Terms of Service.</p>
                        <p><b>5.2.</b> In order to use the Food911 Service, the User must create a user account by following the registration instructions in the Food911 Service. The Food911 Service credentials are personal (unless otherwise agreed between Food911
                            and the Customer Organization). The User shall ensure that any user account credentials and equivalent information required to access the user account of the User or the Customer Organization are kept confidential and used in a
                            secure manner not accessible by third parties. A User may have only one personal user account.</p>
                        <p><b>5.3.</b> Where it is suspected that any unauthorized person has become aware of a User’s user account credentials or has access to the user account of the User, the User shall immediately inform Food911 thereof. The User shall be responsible
                            for any use of the Food911 Service and any activity under the user account of the User.</p>
                        <p><b>5.4.</b> In order to use the Food911 Service, you must submit valid credit card or other payment method information to Food911. Food911 does not store information of your payment instrument, as this is done by a third-party payment service
                            provider used by Food911. You agree to pay for all purchases arising from your use of the Food911 Service except for purchases made through the Food911 at Work Feature at the Customer Organization’s cost. You must keep the credit
                            card and other payment information that you have submitted to your user account updated.</p>
                    </li>
                    <li className='p-2 grid gap-y-2'>
                        <p className="font-semibold text-xl">6. Payments</p>
                        <p><b>6.1.</b> The User, or the Customer Organization in case of Food911 at Work Account, will pay the purchase price set out in the Order by using the relevant payment functionality of the Food911 Service. Food911, or the Food911 group company
                            authorized by Food911, collects all payments from the Users or the Customer Organizations on behalf of the Partner. If the Order is made by using the Food911 at Work Feature, the User confirms the payment on behalf of the Customer
                            Organization through the Food911 at Work Feature. The User’s or Customer Organization’s payment obligation shall arise upon placing an Order through the Food911 Service.</p>
                        <p><b>6.2.</b> The User may fulfil this payment obligation only by using the relevant online payment method provided in the Food911 Service. When the User, or the Customer Organization in case of Food911 at Work Account, has paid the purchase
                            price to Food911, the User or the Customer Organization has fulfilled its payment obligation towards the Partner.</p>
                        <p><b>6.3.</b> In Finland, you can fulfil your payment obligation by using a lunch benefit as payment method, if the Food911 Service is compatible with the lunch benefit provided by your employer. You are responsible for ensuring that the Order
                            costs of food products and delivery services paid with the lunch benefit does not exceed the limit of the lunch benefit’s daily value. The amount exceeding this value can be paid with another acceptable payment method in the Food911
                            Service. You are obligated to comply with the tax administration’s guidelines regarding use of the lunch benefit. Please note for example that the lunch benefit is personal and does not cover purchase of gift cards, utility products,
                            groceries, convenience food or processed food. You are therefore not entitled to buy such products with the lunch benefit through the Food911 Service.</p>
                        <p><b>6.4.</b> The payment will be automatically charged from the User’s credit card, lunch benefit or charged through the Customer Organization’s Food911 at Work Feature, upon delivery of the Order. Food911 is entitled to make an authorisation
                            hold on behalf of the Partner on the User’s credit card, lunch benefit or Customer Organization’s Food911 at Work Feature once the Order has been placed through the Food911 Service. Food911 uses a third-party payment service provider
                            for processing of payments. In Serbia all payments are processed locally within Serbia by a third-party payment service provider.</p>
                        <p><b>6.5.</b> Cash is an additional payment method available in Slovenia, Azerbaijan, Croatia, Cyprus, Georgia, Greece, Kazakhstan, Malta, Serbia, Japan, Czech Republic and Slovakia. Refunds in cash orders will be made in Food911 credits or
                            via another appropriate or legally required method. If a User who has made a cash order is not in the agreed place of delivery at the agreed time or 5 minutes thereafter, Food911's customer support will contact the User and agree
                            on an appropriate arrangement for the payment. If the customer support cannot reach the User within 24 hours of the no-show, Food911 will take appropriate measures which may include disabling the cash option from the User or even
                            disabling the User's account in the Food911 Service for further Orders.</p>
                        <p>
                            <b>6.6.</b> In Israel payments are processed either locally within Israel or arranged by Food911 Enterprises Oy through third party payment service providers. In case payments are arranged by Food911 Enterprises Oy through third party payment service providers,
                            Food911 Enterprises Oy is responsible for possible refunds, complaints and other claims related to your transactions in the Food911 Service.
                        </p>
                    </li>
                    <li className='p-2 grid gap-y-2'>
                        <p className="font-semibold text-xl">7. Food911 credits and tokens</p>
                        <p><b>7.1.</b> The Users of the Food911 Service can obtain Food911 credits or tokens e.g. by inviting new users to sign up to the Food911 Service. The User can use Food911 credits and tokens to get a discount on their purchases in the Food911
                            Service.
                        </p>
                        <p><b>7.2.</b> For Users in Denmark section 7.1. is not applicable. For Users in Denmark, you can share your code on social media or as a message by pasting the link from your user profile and inserting it where you wish. Your friends will get
                            Food911 credits when they use your code for their first order.</p>
                        <p><b>7.3.</b> Food911 may unilaterally determine the conditions applicable to the granting, use and validity of the Food911 credits and tokens.</p>
                        <p><b>7.4.</b> Any signing-up bonus credits or tokens can be used only once.</p>
                        <p><b>7.5.</b> Food911 credits and tokens cannot be exchanged to cash.</p>
                        <p><b>7.6.</b> The Food911 credits and tokens will be nullified if Food911 detects any abuse of Food911 credits or tokens or suspects or detects that Food911 credits or tokens have been granted on incorrect grounds. In such cases, Food911 shall
                            invoice the amount that was paid using such Food911 credits or tokens.</p>

                    </li>
                    <li className='p-2 grid gap-y-2'>
                        <p className="font-semibold text-xl">8. Delivery of an Order</p>
                        <p><b>8.1.</b> If the User orders the delivery of the Order through the Food911 Service, the Order will be delivered to the location confirmed by the User in the Food911 Service. The User also has to provide a street address for the confirmed
                            location in the Food911 Service. If no-contact delivery is applied to your Order, please note that the goods shall be deemd to be delivered when they are left at the door and the courier partner marks the Order status as "delivered"
                            on the Food911 Service after which you bear the risk of the items in the Order.</p>
                        <p><b>8.2.</b> The User must be available to receive calls at the phone number the User has submitted to the Food911 Service. If the phone number provided by the User cannot be reached, the delivery may be cancelled by Food911 or the Partner
                            and the User or Customer Organization may be charged for the full price of the Order (not applicable to Users located in Slovenia).</p>
                        <p>
                            <b>8.3.</b> The User may place an Order to be delivered as soon as possible (standard delivery method) or by pre-ordering a certain delivery time, to the extent these options are available on the Food911 Service at the time of placing the Order.
                        </p>
                        <p><b>8.4.</b> Standard delivery option: The User must be present at the confirmed location set out in the Order between the time of making the Order until the products set out in the Order have been received. If the User is not available at
                            the location, they have confirmed within five minutes of the arrival of the delivery of the ordered products and the User does not respond after two contact attempts by the courier partner making the delivery, the delivery may
                            be cancelled by Food911 or the Partner and the User or Customer Organization may be charged for the full price of the Order.</p>
                        <p><b>8.5.</b> “Pre-order” delivery option: the User must be present at the confirmed location between ten minutes prior to the pre-ordered delivery time until the moment of delivery in the Order to receive the Order.</p>

                    </li>
                    <li className='p-2 grid gap-y-2'>
                        <p className="font-semibold text-xl">9. Picking up and collecting an Order at the Partner’s point of sale</p>
                        <p><b>9.1.</b> If the User has not ordered delivery of the Partner’s products set out in the Order, but the collection at the Partner’s point of sale, the products can be collected at the point of sale of the Partner the User has chosen in connection
                            with the Order. The User will receive a separate electronic confirmation when the products are ready for collection. The Partner or Food911 may set conditions for the identification of the User when picking up the products set
                            out in the Order.</p>
                        <p><b>9.2.</b> The Partner shall retain the ordered products for 60 minutes after the Partner has notified the User that the Order is ready for collection. However, this obligation is limited to the opening hours of the particular Partner’s
                            point of sale and the Order has to be collected before the closing time of said point of sale of the Partner.</p>
                        <p></p>
                    </li>
                    <li className='p-2 grid gap-y-2'>
                        <p className="font-semibold text-xl">10. Eat-in Order</p>
                        <p><b>10.1.</b> If the User has selected the option to consume the products set out in the Order at the point of sale of the Partner, the User will receive a separate electronic confirmation about the expected time of when the products will
                            be ready to be consumed at the Partner’s point of sale.</p>
                    </li>
                    <li className='p-2 grid gap-y-2'>
                        <p className="font-semibold text-xl">11. Time estimates</p>
                        <p><b>11.1.</b> Any delivery time or pick-up time or other time estimate communicated to the User by the Partner or Food911 in the Food911 Service are only estimated times. There is no guarantee that the Order will be delivered or available
                            for pick-up or consumption at the estimated time. Delivery times of the products may also be affected by factors such as traffic jams, rush hours and weather conditions.</p>
                    </li>
                    <li className='p-2 grid gap-y-2'>
                        <p className="font-semibold text-xl">12. Intellectual Property Rights</p>
                        <p><b>12.1.</b> All Intellectual Property Rights in or related to the Food911 Service and thereto related documentation and all parts and copies thereof shall remain exclusively vested with and be the sole and exclusive property of Food911 and/or
                            its affiliates/subcontractors/licensors. <b>“Intellectual Property Rights”</b> shall mean copyrights and related rights (including database and catalogue rights and photography rights), patents, utility models, design rights, trademarks,
                            tradenames, trade secrets, know-how and any other form of registered or unregistered intellectual property rights.</p>
                        <p><b>12.2.</b> These Terms of Service do not grant the User any Intellectual Property Rights in the Food911 Service and all rights not expressly granted hereunder are reserved by Food911 and its subcontractors/licensors.</p>
                        <p><b>12.3.</b> Apple shall not be responsible for any investigation, defence, settlement or discharge of any claim that the iOS Food911 App or your use of it infringes any third-party intellectual property right.
                        </p>
                        <p><b>12.4.</b> User grants to Food911 a worldwide, royalty-free, sublicensable, and transferable right to use, modify, reproduce, distribute, display and publish any content provided by User in connection with the Food911 Service. Such content
                            may include photographs, data, information, feedback, suggestions, text, and other materials that are uploaded, posted or otherwise transmitted relating to the Food911 Service.</p>
                    </li>
                    <li className='p-2 grid gap-y-2'>
                        <p className="font-semibold text-xl">13. Additional Provisions for use of the Food911 Service</p>
                        <p><b>13.1.</b> The Food911 Service is only available to persons of the age of 18 years or older. You may not register as a User if you do not fulfil this requirement.</p>
                        <p><b>13.2.</b> The User shall observe all applicable rules and regulations when using the Food911 Service, including the purchase of alcohol and tobacco products. To the extent delivery of alcoholic beverages or tobacco products is available
                            in the country where the User is located, the User may be refused delivery of alcoholic beverages or tobacco products in case the User is unable to provide a valid photo ID to Food911’s courier partner delivering the Order proving
                            that the User is at least 18 years of age. The User may be refused delivery of alcoholic beverages also in other cases stipulated by laws in the country where the User is located (e.g., if the User is showing signs of intoxication).</p>
                        <p><b>13.3.</b> Food911 is constantly developing the Food911 Service and Food911 may change or remove different parts of the Food911 Service, including features, the products and Partners available in the Food911 Service in part or in whole.</p>
                        <p><b>13.4.</b> By using the Food911 Service, the User may encounter content or information that might be inaccurate, incomplete, delayed, misleading, illegal, offensive or otherwise harmful. Food911 generally does not review content provided
                            by the Partners. Food911 is not responsible for third parties' (including the Partners') content or information or for any damages arising as a result of the use of or reliance on it.</p>
                        <p><b>13.5.</b> You or the Customer Organization shall be responsible for obtaining and maintaining any devices or equipment (such as telephones) and connections needed for access to and use of the Food911 Service and all charges related thereto.</p>
                        <p><b>13.6.</b> The User will not:
                            <div className="pl-8 py-2">
                                <ul className="list-decimal">
                                    <li>use or attempt to use another person’s personal Food911 account and/or access another person’s personal payment data through the Food911 Service or use another person’s personal payment cards when using
                                        the Food911 Service, without consent of that other person;</li>
                                    <li>copy, modify or create derivative works of the Food911 Service or any related technology;</li>
                                    <li>reverse engineer, decompile, disassemble, decipher or otherwise attempt
                                        to derive the source code for the Food911 Service or any related technology, or any part thereof;</li>
                                    <li>remove any copyright, trademark or other proprietary rights notices contained in or on the Food911 Service;</li>
                                    <li>remove, cover
                                        or obscure any advertisement included on the Food911 Service;</li>
                                    <li>collect, use, copy, or transfer any information obtained from the Food911 Service without the consent of Food911;</li>
                                    <li>use bots or other automated methods to use the Food911 Service;</li>
                                    <li>create a Food911 account using a fake identity or an identity of another person;</li>
                                    <li>access the Food911 Service except through the interfaces expressly provided by Food911, such as the Food911 App
                                        and Food911 Website.</li>
                                </ul>
                            </div>
                        </p>
                        <p><b>13.7.</b> Food911 is entitled to remove a User from the Food911 Service with immediate effect and/or refuse or cancel any Orders from a User if:
                            <div className="pl-8 py-2">
                                <ul className="list-decimal">
                                    <li>the User abuses the Food911 Service or causes any harm or detriment to the use of the Food911 Service or the Partners or Food911 or Food911’s employees, </li>
                                    <li>Food911 has reasonable belief of fraudulent acts by the User when using the Food911 Service,</li>
                                    <li>the User places a false Order (for example by not paying or by not being present on the delivery or collection location in order to receive the Order) or otherwise fails to comply with his obligations arising from these Terms of Service;</li>
                                    <li>there is reasonable doubt about the correctness or authenticity of the Order. If Food911 cancels an Order that has already been paid for, Food911 shall transfer that amount into the same user account or Food911 at Work Account as the one from which the payment was made by the User.</li>
                                </ul>
                            </div>
                        </p>
                        <p><b>13.8.</b> The controller of the personal data collected about Users is Food911 Enterprises Oy, unless otherwise indicated. Food911 Enterprises Oy shall process any personal data collected from the User in accordance with <span className='text-blue-500'>Food911 Privacy Statement</span> .
                        </p>
                        <p><b>13.9.</b> The User must comply with applicable third-party terms of agreement when using the Food911 App or the Food911 Service.</p>
                        <p><b>13.10.</b> The User represents and warrants that
                            <div className="pl-8 py-2">
                                <ul className="list-decimal">
                                    <li> they are not located in a country that is subject to a U.S. Government embargo, or that has been designated by the U.S. Government as a “terrorist supporting” country; and</li>
                                    <li>they are not listed on any U.S. Government list of prohibited or restricted parties.</li>
                                </ul>
                            </div>
                        </p>
                    </li>
                    <li className='p-2 grid gap-y-2'>
                        <p className="font-semibold text-xl">14. Term and termination</p>
                        <p><b>14.1.</b> These Terms of Service are in force as a binding agreement between Food911 and the User until further notice as long as the User is using the Food911 Service. </p>
                        <p><b>14.2.</b> The User can discontinue the use of the Food911 Service at any time. Food911 can discontinue providing the Food911 Service permanently or temporarily at any time.</p>

                    </li>
                    <li className='p-2 grid gap-y-2'>
                        <p className="font-semibold text-xl">15. Defects and complaints</p>
                        <p><b>15.1.</b> Please note that the Food911 Service may at any time be interrupted or permanently discontinued. The Food911 Service may also be temporarily suspended. Do not use the Food911 Service for backing up any data. While we do our best
                            in providing you our platform and services (including new and/or temporary services such as no-contact deliveries, deliveries with novel technology or deliveries of new product categories) error-free, Food911 does not promise or
                            guarantee you anything regarding the reliability, functionality, timeliness, quality or suitability of the Food911 Service, its features or any service offered by Food911. Food911 does not promise or guarantee anything that is
                            not expressly mentioned in these Terms of Service.</p>
                        <p><b>15.2.</b> The Partner is solely liable for the contents, quality, safety and packaging of their products sold through the Food911 Service and has a statutory defect liability for their products as set out in the applicable laws. You are
                            responsible for inspecting the products in your Order without undue delay after receiving the Order. If there are any defects or other quality shortcomings in the products of your Order, then you should contact either the customer
                            service of Food911, acting on behalf of the Partner, or the Partner directly and include a clear description of the defects or shortcomings. Regarding consumer goods, notices of defects should be made within a reasonable time from
                            detecting the defect, however no later than within two months after being detected. Please note that in case of defects in foodstuffs or other products that by their nature spoil or age rapidly, it is crucial that you notify the
                            defect as soon as possible to enable proper investigation and verification of the defect. Please note that Food911 or the Partner may ask you to send a picture of the product in question in order to document and verify the defects.
                            In case of a defect or a non-conformity in the products of your Order, you are entitled to reimbursement in accordance with applicable laws.</p>
                        <p>
                            <b>15.3.</b> The Partner is solely liable for any defects in the contents and preparation or packaging of the Order or other shortcomings in the performance of the Purchase Agreement. Food911 is not responsible for the information provided by the Partner on
                            the Food911 Service and does not give any warranty on the availability, quality or suitability of the products.
                        </p>
                        <p><b>15.4.</b> When the User who is located in Israel and uses Cibus as the payment method, any potential refunds will be only made in Food911 credits and not as Cibus allowance.</p>
                        <p><b>15.5.</b> Apple has no obligation whatsoever to furnish any maintenance and support services with respect to the Food911 App. To the extent that any maintenance or support is required by applicable law, we, not Apple, shall be obligated
                            to furnish any such maintenance or support. To the extent any warranty exists under law that cannot be disclaimed, we, not Apple, shall be solely responsible for such warranty. We, not Apple, are responsible for addressing any
                            claims of the User or any third party relating to the iOS Food911 App or the User’s possession and/or use of the iOS Food911 App, including, but not limited to: (i) product liability claims; (ii) any claim that the iOS Food911
                            App fails to conform to any applicable legal or regulatory requirement; and (iii) claims arising under consumer protection, privacy, or similar legislation, including in</p>
                    </li>
                    <li className='p-2 grid gap-y-2'>
                        <p className="font-semibold text-xl">16. Applicable Law and Dispute Resolution</p>
                        <p><b>16.1.</b> These Terms of Service shall be governed by and construed in accordance with the laws of the country where the User is located. </p>
                        <p><b>16.2.</b> If you are a consumer, please note that you cannot be deprived of the rights granted to you by the mandatory consumer protection laws of your country of domicile. </p>
                        <p><b>16.3.</b> Disputes arising from these Terms of Service shall be resolved by the competent courts of the country where the User is located. A consumer may, however, always institute proceedings in the competent court of its domicile. If
                            you are a User domiciled in the EU, you and Food911 may also use the ODR platform managed by the European Commission to settle disputes. This platform can be found on <span className='text-blue-500'>ec.europa.eu/odr.</span> You may also refer a dispute to the consumer
                            disputes board or other corresponding out-of-court institution in your domicile (such as, by way of example, the Finnish Consumer Disputes Board, <span className='text-blue-500'>kkv.fi/kuluttajaneuvonta</span> , in Finland, the Czech Trade Inspection Authority, <span className='text-blue-500'>coi.cz</span>,
                            in the Czech Republic or the Polish Permanent Amicable Consumer Court at the competent Provincial Inspectorate of Trade Inspection, <span className='text-blue-500'>uokik.gov.pl</span>, in Poland).</p>

                    </li>
                    <li className='p-2 grid gap-y-2'>
                        <p className="font-semibold text-xl">17. Amendments</p>
                        <p><b>17.1.</b> These User Terms of Service are subject to amendments.</p>
                        <p><b>17.2.</b> Food911 shall publish the amended User Terms of Service at the Food911 Website. Food911 shall inform the User of any changes that are material by nature on the Food911 Service or by email to the email address submitted to the
                            Food911 Service by the User. If the User does not agree to any amended Food911 Terms of Service, they shall discontinue the use of the Food911 Service.</p>

                    </li>
                    <li className='p-2 grid gap-y-2'>
                        <p className="font-semibold text-xl">18. Assignment</p>
                        <p><b>18.1.</b> Food911 shall be entitled to assign all or any of its rights or obligations hereunder in whole or part to an affiliate or successor or to a purchaser or acquirer of its business assets relating to Food911 Service without the
                            User’s prior consent.</p>
                        <p><b>18.2.</b> The User shall not be entitled to assign any of its rights or obligations hereunder in whole or part.</p>
                        <p>Updated 24.5.2021</p>
                    </li>
                </ul>
            </div>
            <div className='grid gap-y-2 p-2'>
                <h2 className='text-3xl font-bold py-4'>Food911 privacy statement</h2>
                <p>
                    Food911 Enterprises Oy and certain Food911 group companies (“Food911”, “we”) process personal data of their users of Food911’s food ordering services (“Food911 App”) and the visitors of the website <span className='text-blue-500'>Food911.com</span> (“Website”). In this Privacy Statement, the
                    word “Food911 Services” refers jointly to the Website and the Food911 App. In this Privacy Statement, the word “User” or “you” refers jointly to our and our group companies’ customers, representatives and other authorized users of our
                    customer organizations, potential customers and the users of the Food911 Services. Our Privacy Statement explains, for example, the types of personal data we process, how we process the personal data and how you may exercise your rights
                    as a data subject (e.g. right to object, right of access). Some of our services might be subject to a separate privacy policy. If a separate privacy policy applies to a particular service, we will post it in connection with the service
                    in question. This Privacy Statement may be updated from time to time in order to reflect the changes in data processing practices or otherwise. You can find the current version on the Website. We will not make substantial changes to this
                    Privacy Statement or reduce the rights of the Users under this Privacy Statement without providing a notice thereof.
                </p>
                <ul>
                    <li className='p-2 grid gap-y-2'>
                        <p className="font-semibold text-xl">1. DATA CONTROLLER(S)</p>
                        <p>This Privacy Statement applies to processing of personal data carried out by Food911. In relation to the processing of personal data of Users in the below countries, Food911 Enterprises Oy and the local Food911 group company are acting
                            as joint data controllers. This means that Food911 Enterprises Oy and the local Food911 group company together determine the purposes for and means by which personal data is processed. The country-specific joint controllers are
                            listed below:
                        </p>
                        <div className="p-2">

                            <p>
                                <b>Food911 Česko s.r.o.</b>, Na poříčí 1047/26, Nové Město, 110 00 Prague 1, Czech Republic, if the User is located in the Czech Republic,
                            </p>
                            <p>
                                <b>Food911 Polska sp. z o.o.</b>, ul. Koszykowa 54, piętro 00-675 Warsaw, Poland, if the User is located in Poland,
                            </p>
                            <p>
                                <b>Food911 Enterprises Israel Ltd.</b>, Allenby St. 113, 6581706 Tel Aviv, Israel, if the User is located in Israel,
                            </p>
                            <p>
                                <b>Food911 Technologies Greece Provision of Food Services S.A.</b>, Leoforos Vouliagmenis 26, 11743 Athens, Greece, if the User is located in Greece,
                            </p>
                            <p>
                                <b>Food911 d.o.o. Beograd-Stari Grad</b>, Bulevar vojvode Bojovića 8, 11000 Belgrade, Serbia, if the User is located in Serbia,
                            </p>
                            <p>
                                <b>Food911 Azerbaijan LLC</b>, Yasamal district, Hyatt Tower 2, 4th floor, 8 Izmir Street, AZ1065 Baku, Azerbaijan, if the User is located in Azerbaijan,
                            </p>
                            <p>
                                <b>Food911 Technologies Kazakhstan LLP</b>, Park View, Kunaev St 77, 050000, Almaty, Kazakhstan, if the User is located in Kazakhstan,
                            </p>
                            <p>
                                <b>Food911 Slovensko s. r. o.</b>, Palisády 36, 811 06 Bratislava – Staré Mesto, Slovak Republic, registered in the Commercial register of the District Court Bratislava I, Section: Sro, Insert No.: 137637/B, if the User is located in Slovakia,
                            </p>
                            <p>
                                <b>Food911 Malta Limited</b>, 171, Old Bakery Street, Valletta, VLT 1455, Malta, if the User is located in Malta,
                            </p>
                            <p>
                                <b>Food911 Norway AS</b>, Øvre Slottsgate 4, 0157 Oslo, Norway, if the User is located in Norway,
                            </p>
                            <p>
                                <b>Food911 Latvija SIA</b>, Elizabete iela 45/47, Riga, Latvia, if the User is located in Latvia,
                            </p>
                            <p>
                                <b>UAB Food911 LT</b>, Jasinskio g. 14a-57, LT-01135, Vilnius, Lithuania, if the User is located in Lithuania,
                            </p>
                            <p>
                                <b>Food911 Japan K.K.</b>, Portal Point Shibuya 7F, 11-3, Jinnan 1-chome, Shibuya-ku, Tokyo, if the User is located in Japan,
                            </p>
                            <p>
                                <b>Food911 Georgia LLC</b>, 49d Chavchavadze Ave 179, Tbilisi, Georgia, if the User is located in Georgia,
                            </p>
                            <p>
                                <b>Food911 Eesti OÜ</b>, Aia 10 a, 10111, Tallinn, Estonia, if User is located in Estonia,
                            </p>
                            <p>
                                <b>Food911 Zagreb d.o.o.</b>, Metalčeva ul. 5, 1st floor, 10000 Zagreb, Croatia, if the User is located in Croatia,
                            </p>
                            <p>
                                <b>Food911 Danmark ApS</b>, Borgergade 10, 2 sal., 1300 Copenhagen K, Denmark, if User is located in Denmark.
                            </p>
                            <p>
                                <b>Food911 Sverige AB</b>, Celsiusgatan 10, 11230, Stockholm, Sweden, if the user is located in Sweden.
                            </p>
                            <p>
                                <b>Food911 Cyprus Limited</b>, Stasikratous 35 3rd floor, office 302, 1065, Nicosia, Cyprus if the User is located in Cyprus.
                            </p>
                            <p>
                                <b>Food911 Magyarország Kft</b>, Salétrom utca 4. földszint, 1085, Budapest, Hungary, if the User is located in Hungary.
                            </p>
                            <p>
                                <b>Food911, tehnologije d.o.o. Ljubljana</b>, Parmova ulica 53, 1000, Ljubljana, Slovenia, if the User is located in Slovenia.
                            </p>
                            <br />
                            <p>
                                Food911 Enterprises Oy has been appointed responsible for handling all data subject requests and questions relating to the personal data processing of the Food911 group on behalf of the local joint controllers.
                            </p>
                        </div>

                    </li>
                    <li className='p-2 grid gap-y-2'>
                        <p className="font-semibold text-xl">2. FOOD911’S CONTACT DETAILS</p>
                        <div>
                            <p><b>Name:</b>  <i>Food911 Enterprises Oy</i></p>
                            <p><b>Business ID:</b>  <i>2646674-9</i></p>
                            <p><b>Correspondence address:</b>  <i>Arkadiankatu 6, 00100 Helsinki, Finland</i></p>
                            <p><b>E-mail address:</b>  <i>support@Food911.com</i></p>
                            <p className='text-blue-500'>Food911.com</p>
                        </div>
                        <p>
                            Food911 has appointed a data protection officer who you can reach through the above contact details.
                        </p>
                    </li>

                    <li className='p-2 grid gap-y-2'>
                        <p className="font-semibold text-xl">3. PERSONAL DATA PROCESSED AND SOURCES OF DATA</p>
                        <div className="p-2">
                            <p>The personal data collected and processed by us can be divided into two general data categories: User Data and Analytics Data.</p>
                            <p className='underline'>User Data</p>
                            <p>
                                User Data is personal data collected directly from you or from our customer organization on behalf of which you are using the Food911 Services (“Customer Organization”), as the case may be. We may collect User Data from our Users and Customer Organizations
                                in a variety of ways, including, after conclusion of a service agreement with the Customer Organization or when Users register to the Food911 Services, subscribe to a newsletter or fill out a form. Further, please note that we
                                also collect details of any transactions and payments you carry out through the Food911 Services.
                            </p>
                            <p className='italic'>User Data that is necessary in order to use the Food911 Services</p>
                            <p>The following personal data collected and processed by us is necessary in order for a proper performance of the contract between you and us as well as for our legitimate interest whilst fulfilling our contractual obligations towards
                                our Customer Organizations and for the compliance with our legal obligations.</p>
                            <p>After conclusion of the service agreement between us and the Customer Organization, the Customer Organization provides us with your full name and email address.  </p>
                            <p>When you register to the Food911 Services and create a user account, you need to provide us with the following information:</p>
                            <ul className='text-gray-500 list-disc pl-8 py-2'>
                                <li>full name</li>
                                <li>telephone number</li>
                                <li>email address </li>
                                <li>information relating to your payment instrument(s) such as the number of your payment instrument(s) and the expiration date of your payment instrument(s) (required for the purposes of ordering food products via the Food911 Services,
                                    however not stored by Food911, since Food911 uses a third-party payment service provider for processing of payments)</li>
                            </ul>
                            <p className='italic'>User Data you give us voluntarily</p>
                            <p>Your user or customer experience may be enhanced by providing us with the following informati</p>
                            <p className='font-bold'>Additional Account Information:</p>
                            <ul className='text-gray-500 list-disc pl-8 py-2'>
                                <li>a picture</li>
                                <li>delivery address</li>
                                <li>location data (if you consent to the processing of your location data)</li>
                                <li>other information you provide either when creating a user account or later when modifying your account profile</li>
                            </ul>
                            <p className='font-bold'>Other Information. We may also process other information provided by you voluntarily such as:</p>
                            <ul className='text-gray-500 list-disc pl-8 py-2'>
                                <li>information you provide when rating our services</li>
                                <li>marketing opt-ins and opt-outs</li>
                                <li>information you provide by phone or in email or chat correspondence with us, including call recordings of your calls with our customer service </li>

                            </ul>
                            <p>In addition to User Data collected from you and the Customer Organization, we process certain personal data third party service providers provide about you. </p>
                            <p>If you connect or login to your account with Facebook, Facebook shares with us personal information about you such as your profile picture, a sample of your Facebook friends and your Facebook ID. </p>
                            <p className='underline'>Analytics Data</p>
                            <p>Although we do not normally use Analytics Data to identify you as an individual, you can sometimes be recognized from it, either alone or when combined or linked with User Data. In such situations, Analytics Data can also be considered
                                personal data under applicable laws and we will treat such data as personal data.</p>
                            <p>We may automatically collect the following Analytics Data when you visit or interact with the Food911 Services:</p>
                            <p className='font-bold'>Device Information. We collect the following information relating to the technical device you use when using the Food911 Services:</p>
                            <ul className='text-gray-500 list-disc pl-8 py-2'>
                                <li>device and device identification number, device IMEI</li>
                                <li>country</li>
                                <li>IP address</li>
                                <li>browser type and version</li>
                                <li>operating system</li>
                                <li>Internet service providers</li>
                                <li>advertising identifier of your device</li>
                                <li>advertising identifier of your device</li>
                                <li>visitor identifier</li>
                            </ul>
                            <p className='font-bold'>Usage Information. We collect information on your use of the Food911 Services, such as:</p>
                            <ul className='text-gray-500 list-disc pl-8 py-2'>
                                <li>time spent on the Food911 Services </li>
                                <li>interaction with the Food911 Services</li>
                                <li>information on your orders made through the Food911 Services</li>
                                <li>the URL of the website you visited before and after visiting the Food911 Services</li>
                                <li>the time and date of your visits to the Food911 Services</li>
                                <li>the sections of the Food911 Services you visited</li>
                                <li>the products you searched for while using the Food911 Services</li>
                            </ul>
                            <p className='underline'>Cookies</p>
                            <p>We use various technologies to collect and store Analytics Data and other information when the Users visit the Food911 Services, including cookies and web beacons.</p>
                            <p>Cookies are small text files sent and saved on your device that allows us to identify visitors of the Food911 Services and facilitate the use of the Food911 Services and to create aggregate information of our visitors. This helps us
                                to improve the Food911 Services and better serve our Users. The cookies will not harm your device or files. We use cookies to tailor the Food911 Services and the information we provide in accordance with the individual interests
                                of our Users.
                            </p>
                            <p>You can manage the cookie preferences you have submitted earlier by clicking <span className='text-blue-500'>here</span>.</p>
                            <p>The Users may choose to set their web browser to refuse cookies, or to alert when cookies are being sent. For example, the following links provide information on how to adjust the cookie settings on some popular browsers: </p>
                            <ul className='text-blue-500 list-none pl-8 py-2'>
                                <li>Safari</li>
                                <li>Google Chrome</li>
                                <li>Internet Explorer</li>
                                <li>Mozilla Firefox</li>
                            </ul>
                            <p>
                                Please note that some parts of the Food911 Services may not function properly if use of cookies is refused.
                            </p>
                            <p>A web beacon is a technology that allows identifying readers of websites and emails e.g. for identifying whether an email has been read.</p>
                            <p className='underline'>Web analytics services</p>
                            <p>
                                The Food911 Services use Google Analytics and other web analytics services to compile Analytics Data and reports on visitor usage and to help us improve the Food911 Services. For an overview of <span className='text-blue-500 underline'>Google Analytics</span>, please visit Google Analytics. It is possible
                                to opt-out of Google Analytics with the following browser add-on tool: <span className='text-blue-500 underline'>Google Analytics opt-out add-on</span>.
                            </p>
                            <p className='underline'>Advertising and visitor identifiers</p>
                            <p>The Food911 Services use pseudonymized identifiers to track and predict your app and service usage and preferences.</p>
                            <p>You can opt-out of the use of these identifiers by changing your operating system settings as follows:</p>
                            <p>
                                Advertising identifiers can be disabled on iOS mobile devices by turning on the Limit Ad Tracking tab (Settings → Privacy → Advertising → Limit Ad Tracking). For an overview and more information on the advertising identifier, please see  <span className='text-blue-500 underline'>Apple Advertising and Privacy site</span>.
                            </p>
                            <p>
                                Food911 visitor identifiers can be disabled on iOS and Android mobile devices by changing your settings (for iOS: Settings → Food911 → Food911 settings → Limit Tracking, and for Android: Food911 application → Profile tab → Settings icon in the top-right
                                corner).
                            </p>
                        </div>

                    </li>
                    <li className='p-2 grid gap-y-2'>
                        <p className="font-semibold text-xl">4. PURPOSES AND LEGITIMATE GROUNDS FOR THE PROCESSING OF YOUR PERSONAL DATA</p>
                        <p className='underline'>Purposes</p>
                        <p>There are several purposes of the processing of your personal data by Food911:</p>
                        <i>To provide the Food911 Services and carry out our contractual obligations (legal ground: performance of a contract and legitimate interest)</i>
                        <p>Food911 processes your personal data to be able to offer the Food911 Services to you under the contract between you and Food911 or between Customer Organization and Food911. </p>
                        <p>We use the data for example to handle your payments or any refunds (where applicable) and to provide our partners (the restaurants and our courier partners) with the information necessary for the preparation or delivery of your order.
                            If you contact our customer service, we will use the information provided by you to answer your questions or solve your complaint.</p>
                        <i>For our legal obligations (legal ground: compliance with a legal obligation)</i>
                        <p>Food911 processes data to enable us to administer and fulfil our obligations under law. This includes data processed for complying with our bookkeeping obligations and providing information to relevant authorities such as tax authorities.</p>
                        <i>For claims handling and legal processes (legal ground: legitimate interest)</i>
                        <p>Food911 may process personal data in relation to claims handling, debt collection and legal processes. We may also process data for the prevention of fraud, misuse of our services and for data, system and network security.</p>
                        <i>For customer communication and marketing (legal ground: legitimate interest)</i>
                        <p>Food911 processes your personal data to contact you regarding the Food911 Services and to inform you of changes relating to them. Your personal data are also used for the purposes of marketing the Food911 Services to you. </p>
                        <i>For quality improvement and trend analysis (legal ground: legitimate interest)</i>
                        <p>
                            We may also process information about your use of the Food911 Services to improve the quality of the Food911 Services e.g., by analysing any trends in the use of the Food911 Services. In order to ensure that our services are in line with your needs, personal
                            data can be used for things like customer satisfaction surveys. When possible, we will do this using only aggregated, non-personally identifiable data.
                        </p>
                        <p className='underline'>Legal grounds for processing</p>
                        <p>Food911 processes your personal data to perform our contractual obligations towards you or the Customer Organization and to comply with legal obligations. Furthermore, we process your personal data to pursue our legitimate interest
                            to run, maintain and develop our business and to create and maintain customer relationships. When choosing to use your data on the basis of our legitimate interests, we weigh our own interests against your right to privacy and
                            e.g., provide you with easy to use opt-out from our marketing communications and use pseudonymized or non-personally identifiable data when possible.</p>
                        <p>In some parts of the Food911 Services, you may be requested to grant your consent for the processing of personal data. In this event, you may withdraw your consent at any time.</p>
                        <p>If you place an order with a Partner who is selling you medicine or other products and services relating to your health, Food911 may process a limited amount of personal data concerning your health  in connection to such order. Food911
                            processes the personal data to the extent necessary to perform the contract between you and Food911 for purposes of managing and delivering your Order. Food911 adheres to any additional safeguards that apply to processing such
                            personal data under applicable laws and regulations. If required under applicable laws, Food911 will ask for separate consent for processing such personal data and you may withdraw such consent anytime through your profile settings
                            or contacting Food911 support services at <span className='text-blue-500'>support@Food911.com</span>.</p>
                        <p><i>For Users located in Israel: </i>By entering, connecting to, accessing or using the Food911 Services, you agree and consent to the terms and conditions set forth in this Privacy Statement, including to the collection, processing, transfer
                            and use of your personal data which is collected as part of the Food911 Services. If you disagree to any term provided herein, you may not access or use the Food911 Services. You also acknowledge and confirm that you are not required
                            to provide us with your personal data and that such information is voluntarily provided to us</p>

                    </li>
                    <li className='p-2 grid gap-y-2'>
                        <p className="font-semibold text-xl">5. TRANSFER TO COUNTRIES OUTSIDE EUROPE</p>
                        <p>Food911 stores your personal data primarily within the European Economic Area. However, we have service providers and operations in several geographical locations. As such, we and our service providers may transfer your personal data
                            to, or access it in, jurisdictions outside the European Economic Area or the User’s do</p>
                        <p>We will take steps to ensure that the Users’ personal data receives an adequate level of protection in the jurisdictions in which they are processed. We provide adequate protection for the transfers of personal data to countries outside
                            of the European Economic Area through a series of agreements with our service providers based on the <span className='text-blue-500'>Standard Contractual Clauses</span> or through other appropriate safeguards.</p>
                        <p>More information regarding the transfers of personal data may be obtained by contacting us on any of the addresses indicated above. </p>

                    </li>
                    <li className='p-2 grid gap-y-2'>
                        <p className="font-semibold text-xl">6. RECIPIENTS</p>
                        <p>
                            We only share your personal data within the organization of Food911 if and as far as reasonably necessary for the purposes of this Privacy Statement.
                        </p>
                        <p>
                            or users in Japan: We only share and jointly utilize your personal data mentioned in Article 3 for the purpose of use mentioned in Article 4 within the organization of Food911 if and as far as reasonably necessary for the purposes of this Privacy Statement.
                            Food911 Enterprises Oy is the entity responsible for the management of jointly used Personal Information.
                        </p>
                        <p>
                            We do not share your personal data with third parties outside of Food911’s organization unless one of the following circumstances applies:
                        </p>
                        <i>For the purposes set out in this Privacy Statement and to authorized service providers</i>
                        <p>To the extent that third parties (such as the restaurants which prepare your order, our courier partners who deliver your order and Customer Organization which may pay your order) need access to personal data in order for us to perform
                            the Food911 Services, we provide such third parties with your data. As an example, we may share your phone number with the partner preparing your order if it's necessary e.g. for asking you whether you accept a replacement product
                            in the order or inform you that an item is missing from your order or for any special request clarifications.</p>
                        <p>Furthermore, we may provide your personal data to our affiliates or to authorized service providers who perform services for us (including data storage, accounting, sales and marketing) to process it for us and to payment service providers
                            to process your payments to us.</p>
                        <p>When data is processed by third parties on behalf of Food911, Food911 has taken the appropriate contractual and organizational measures to ensure that your data are processed exclusively for the purposes specified in this Privacy Statement
                            and in accordance with all applicable laws and regulations and subject to our instructions and appropriate obligations of confidentiality and security measures.</p>
                        <p>Please bear in mind that if you provide personal data directly to a third party, such as through a link in the Food911 Services, the processing is typically based on their policies and standards.</p>
                        <p>For legal reasons and legal processes</p>
                        <p>We may share your personal data with third parties outside Food911 if we have a good-faith belief that access to and use of the personal data is reasonably necessary to:</p>
                        <ul className='pl-8 py-2 list-decimal'>
                            <li>meet any applicable law, regulation, and/or court order;</li>
                            <li>detect, prevent, or otherwise address fraud, crime, security or technical issues;</li>
                            <li>protect the interests, properties or safety of Food911, the Users or the public as far as in accordance with the law. When possible, we will inform you about such processing.</li>
                        </ul>
                        <p>For other legitimate reasons</p>
                        <p>If Food911 is involved in a merger, acquisition or asset sale, we may transfer your personal data to the third party involved. However, we will continue to ensure the confidentiality of all personal data. We will give notice to all
                            the Users concerned when the personal data are transferred or become subject to a different privacy statement. </p>
                        <p>With your explicit consent</p>
                        <p>
                            We may share your personal data with third parties outside Food911 when we have your explicit consent to do so. You have the right to withdraw this consent at all times free of charge by contacting us.
                        </p>

                    </li>
                    <li className='p-2 grid gap-y-2'>
                        <p className="font-semibold text-xl">7. STORAGE PERIOD</p>
                        <p>Food911 does not store your personal data longer than is legally permitted and necessary for the purposes of providing the Food911 Services or the relevant parts thereof. The storage period depends on the nature of the information
                            and on the purposes of processing. The maximum period may therefore vary per use.</p>
                        <p>Most personal data relating to a User’s user account with the Food911 Services will be deleted after a period of 90 days have lapsed after the User has deleted its user account with the Food911 Services. Thereafter, a part of the personal
                            data relating to a User’s user account with the Food911 Services may be stored only as long as such processing is required by law or is reasonably necessary for our legal obligations or legitimate interests such as claims handling,
                            bookkeeping, internal reporting and reconciliation purposes. All personal data relating to a User’s user account with the Food911 Services will be deleted within a period of 10 years after the User has deleted its user account
                            with the Food911 Services, with the exception of personal data required in certain rare situations such as legal proceedings.</p>
                        <p>We will store Analytics Data of Users that do not have an account with the Food911 Services for a period of 90 days.</p>

                    </li>
                    <li className='p-2 grid gap-y-2'>
                        <p className="font-semibold text-xl">8. YOUR RIGHTS</p>
                        <i>Right of access</i>
                        <p>You have the right to access and be informed about your personal data processed by us. We give you the possibility to view certain data through your user account with the Food911 Services or request a copy of your personal data.</p>
                        <i>Right to withdraw consent</i>
                        <p>In case the processing is based on a consent granted by the User, the User may withdraw the consent at any time free of charge. Withdrawing a consent may lead to fewer possibilities to use the Food911 Services. The withdrawal of consent
                            does not affect the lawfulness of processing based on consent before its withdrawal.</p>
                        <i>Right to rectify</i>
                        <p>You have the right to have incorrect or incomplete personal data we have stored about you corrected or completed by contacting us. You can correct or update some of your personal data through your user account in the Food911 Services. </p>
                        <i>Right to erasure</i>
                        <p>You may also ask us to delete your personal data from our systems. We will comply with such request unless we have a legitimate ground to not delete the data. </p>
                        <i>Right to object</i>
                        <p>You may have the right to object to certain use of your personal data if such data are processed for other purposes than necessary for the performance of the Food911 Services or for compliance with a legal obligation. If you object
                            to the further processing of your personal data, this may lead to fewer possibilities to use the Food911 Services.</p>
                        <i>Right to restriction of processing</i>
                        <p>You may request us to restrict processing of personal data for example when your data erasure, rectification or objection requests are pending and/or when we do not have legitimate grounds to process your data. This may however lead
                            to fewer possibilities to use the Food911 Services.</p>
                        <i>Right to data portability</i>
                        <p>You have the right to receive the personal data you have provided to us yourself in a structured and commonly used format and to independently transmit those data to a third party.</p>
                        <i>How to use your rights</i>
                        <p>The abovementioned rights may be used by sending a letter or an e-mail to us on the addresses set out above, including the following information: the full name, address, e-mail address and a phone number. We may request the provision
                            of additional information necessary to confirm the identity of the User. We may reject or charge requests that are unreasonably repetitive, excessive or manifestly unfounded. </p>

                    </li>
                    <li className='p-2 grid gap-y-2'>
                        <p className="font-semibold text-xl">9. DIRECT MARKETING</p>
                        <p>The User has the right to prohibit us from using the User’s personal data for direct marketing purposes, market research and profiling made for direct marketing purposes by contacting us on the addresses indicated above or by using
                            the functionalities of the Food911 Services or the unsubscribe possibility offered in connection with any direct marketing messages.</p>

                    </li>
                    <li className='p-2 grid gap-y-2'>
                        <p className="font-semibold text-xl">10. LODGING A COMPLAINT</p>
                        <p>In case the User considers our processing of personal data to be inconsistent with the applicable data protection laws, the User may lodge a complaint with the local supervisory authority for data protection in Finland, the Data Protection
                            Ombudsman (<span className='text-blue-500 underline'>tietosuoja.fi</span>). Alternatively, the User may lodge a complaint with the local supervisory authority for data protection of the User’s country of domicile. </p>

                    </li>
                    <li className='p-2 grid gap-y-2'>
                        <p className="font-semibold text-xl">11. INFORMATION SECURITY</p>
                        <p>We use administrative, organizational, technical, and physical safeguards to protect the personal data we collect and process. Measures include for example, where appropriate, encryption, pseudonymization, firewalls, secure facilities
                            and access right systems. Our security controls are designed to maintain an appropriate level of data confidentiality, integrity, availability, resilience and ability to restore the data. We regularly test the Food911 Services,
                            systems, and other assets for security vulnerabilities. Furthermore, access to personal data by employees of Food911 is restricted and access is subject to what is necessary for purposes of the employee’s work assignments.</p>
                        <p>Should despite of the security measures, a security breach occur that is likely to have negative effects to the privacy of the Users, we will inform the relevant Users and other affected parties, as well as relevant authorities when
                            required by applicable data protection laws, about the breach as soon as possible.</p>
                        <p>Food911 Enterprises Oy has been appointed responsible for handling all data subject requests and questions relating to the personal data processing of the Food911 group on behalf of the local joint controllers.</p>
                        <p>In relation to the processing of personal data of Users in other countries than the ones listed above, Food911 Enterprises Oy acts as the sole data controller.</p>

                    </li>
                    <p className='text-blue-500'>Guidelines for Food911 Partners </p>
                </ul>
            </div>
        </div>
    </div>
}

const Wrapper = styled.div`
    .ant-layout-header{
        background:white !important ;
    }
`