import { motion } from "framer-motion";
import React from "react";
import styled from "styled-components";

export default function Backgroudui({ children, pad = "1.5rem", style = {},bgColor="white" }: any) {
  return (
    <BackgrouduiWrap
      style={{
        padding: `${pad}`,
        // height: "100%",
        backgroundColor:bgColor,
        overflow:"auto",
        ...style
      }}
    >
      {children}
      {/* <div style={style} className="h-full">{children}</div> */}
    </BackgrouduiWrap>
  );
}

const BackgrouduiWrap = styled(motion.div)`
  border-radius: 10px;
  overflow: hidden;

  .title {
    color: #000000;
    opacity: 0.9;
    font-weight: 600;
    font-size: 18px;
    line-height: 42px;
  }

  .card {
    padding: 1.5rem;
    border-radius: 10px;
    height: 240px;
    min-width: 220px;

    .date {
      transform: translateY(6px);
      font-size: 11px;
      line-height: 22px;
      color: #1e1e1e;

      opacity: 0.5;
    }

    hr {
      opacity: 0.1;
      border: 1px solid #000000;
      border-radius: 10px;
    }

    h5 {
      font-weight: 500;
      font-size: 13px;
      line-height: 22px;
      text-transform: uppercase;
      color: #1e1e1e;
      opacity: 0.5;
    }
  }

  .btnform {
    min-width: 100px;
    padding: 0px 10px;
    border-radius: 10px;
    border: none;
    height: 41px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
  }

  .btnform.nav {
    color: #1d8aef;
    background: rgba(29, 138, 239, 0.1);
  }

  .btnform.cancel {
    color: rgba(0, 0, 0, 0.5);
    background: rgba(0, 0, 0, 0.1);
  }
  .btnform.icon {
    border: 1px solid rgba(0, 0, 0, 0.5);
    &:hover{
      color:black;
    }
  }

  .label {
    color: gray;
    line-height: 52px;
    font-size: 12px;
    margin: 0 !important;
  }
  .ant-form-item-has-error {
    .input-with-select {
      &:focus {
        border-color: red !important;
      }
      &:nth-child(1) span {
        input {
          border-color: red !important;
        }
        span {
          border-color: red !important;
          &:focus {
            border-color: red !important;
          }
        }
      }
    }

    .inputfield,
    .input-with-select {
      border-color: red !important;
      &:focus,
      &:hover {
        border-color: red !important;
      }
    }
  }

  .inputfield {
    padding: 0;
    border: none;
    border-bottom: 2px solid lightgray !important;
    &:focus,
    &:hover {
      border-bottom: 2px solid #1d8aef !important;
    }

    input {
      background-color: transparent !important ;
      font-weight: 400;
      padding: 0;
    }

    .ant-input-group-addon {
      padding: 0 !important;
    }
    .ant-select-selection-overflow {
      display: flex;
      gap: 5px;
      flex-wrap: wrap;
      .ant-select-selection-item {
        border-radius: 5px;
        display: flex;
        gap: 3px;
        justify-content: center;
        svg {
          position: relative;
          top: 3px;
          font-size: 10px;
        }
      }
    }
    .ant-input-number {
      width: 100% !important;
    }
  }

  .ant-upload.ant-upload-select {
    display: block;
    border-color: gray;
    outline: none;
    .uploadButton {
      border-radius: 8px;
      width: 100%;
      font-weight: 600;
      color: #1d8aef;
      padding: 20px;
      display: flex;
      align-items: center;
      justify-content: end;
    }
  }
  .ant-select-selector {
    border-bottom-color: lightgray !important;
    padding: 0 !important;
  }

  .input-with-select {
    &:focus {
      border-bottom: 2px solid #1d8aef !important;
    }
    &:nth-child(1) span {
      display: flex;
      justify-content: space-between;
      input {
        border: none;
        border-bottom: 2px solid lightgray !important;
        font-weight: 400;
        padding-left: 0;
        padding-top: 7px;
        border: none;
      }
      span {
        * > * {
          border: none !important;
          margin: 0;
        }
        border: none;
        background: white;
        border-bottom: 2px solid lightgray !important;
        &:focus {
          border-bottom: 2px solid #1d8aef !important;
        }
        width: min-content;
        /* .ant-select-selection-search */
        .ant-select-selector {
          display: flex;
          gap: 9px;
          justify-content: space-between;
          align-items: center;
        }
      }
    }
  }

  .beside-select {
    input {
      padding-top: 0.75rem;
    }
  }

  .ant-table-filter-column {
    display: flex !important;
    justify-content: center;
    gap: 4px;
    .ant-table-filter-trigger {
      position: relative;
      bottom: 4px;
    }
  }

  .pagination-gap {
    .ant-pagination {
      padding-top: 15px !important ;
    }
  }

  .ant-input-password-icon {
    position: relative;
    top: 5px;
    right: 2px;
  }
`;
