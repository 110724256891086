import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "./store";

const initialState: {
  init: boolean;
  restaurant: any;
  user: any;
  category: any;
  menu: any;
  activeKey: string;
} = {
  init: false,
  restaurant: false,
  user: false,
  category: false,
  menu: false,
  activeKey: "1",
};

export const restaurantSlice = createSlice({
  name: "restaurant",
  initialState,
  reducers: {
    invokeAddRestaurant: (state, { payload }) => {
      state.init = payload;
    },
    addRestaurant: (state, { payload }) => {
      state.restaurant = payload;
      state.activeKey = "2";
    },
    addUser: (state, { payload }) => {
      state.user = payload;
      state.activeKey = "3";
    },
    addCategory: (state, { payload }) => {
      state.category = payload;
    },
    addMenu: (state, { payload }) => {
      state.menu = payload;
    },
    setActiveKey: (state, { payload }) => {
      state.activeKey = payload;
    },
    resetAll: (state) => {
      state.init = false;
      state.restaurant = false;
      state.user = false;
      state.category = false;
      state.menu = false;
      state.activeKey = "1";
    },
  },
});

export const {
  addRestaurant,
  addUser,
  addCategory,
  addMenu,
  setActiveKey,
  resetAll,
  invokeAddRestaurant,
} = restaurantSlice.actions;
export const selectRestaurant = (state: RootState) => state.restaurant;

export default restaurantSlice.reducer;
