import { QueryClientProvider, QueryClient } from "react-query";
import { useAppSelector } from "./global/hooks";
import { selectAuth } from "./global/authSlice";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { ConfigProvider } from "antd";
import ruRU from "antd/lib/locale/ru_RU";
import ltLt from "antd/lib/locale/lt_LT";
import enUS from "antd/lib/locale/en_US";
import Login from "./components/auth/login";
import Dashboard from "./components";
import NotFound from "./components/pages/notFound";
import "./App.less";
import TermsOfServices from "./components/pages/Policies/TermsOfServices";
import { ReactQueryDevtools } from "react-query/devtools";
import useDocumentTitle from "./components/shared/useDocument";
import { ErrorBoundary } from "./ErrorBoundary";

function App() {
  useDocumentTitle("Food911 Admin");
  const { isAuthenticated, lng } = useAppSelector(selectAuth);
  const queryClient = new QueryClient();
  const config = {
    locale: lng === "ru" ? ruRU : lng === "lt" ? ltLt : enUS,
    getPopupContainer: (triggerNode: { parentNode: any }) =>
      triggerNode?.parentNode || document.querySelector("body"),
  };

  return (
    <div className="overflow-hidden">
      <ErrorBoundary>
        <QueryClientProvider client={queryClient}>
          <ConfigProvider {...config}>
            <Router>
              <Switch>
                <Route
                  path="/"
                  render={() =>
                    isAuthenticated ? (
                      <Redirect to="/dashboard/home" />
                    ) : (
                      <Login />
                    )
                  }
                  exact
                />
                <Route
                  path="/dashboard"
                  render={() =>
                    isAuthenticated ? <Dashboard /> : <Redirect to="/" />
                  }
                />
                <Route
                  exact
                  path="/policies/terms-of-service"
                  component={TermsOfServices}
                />
                <Route path="*" component={NotFound} />
              </Switch>
            </Router>
          </ConfigProvider>
          {window.location.href.search("ffood911") < 0 && (
            <ReactQueryDevtools position="bottom-left" />
          )}
        </QueryClientProvider>
      </ErrorBoundary>
    </div>
  );
}

export default App;
