import React from "react";

export class ErrorBoundary extends React.Component {
  state: {
    hasError: boolean;
  } = {
    hasError: false,
  };
  constructor(props: any) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: any) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error: any, errorInfo: any) {
    // You can also log the error to an error reporting service
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <div className="h-screen w-screen grid place-items-center">
        <h1 className="font-semibold text-2xl">Something Went Wrong</h1>
        
      </div>;
    }

    return this.props.children;
  }
}
