import { useRef, useState } from "react";
import { motion } from "framer-motion";
import { Form, Input, Button, message } from "antd";
import styled from "styled-components";
import logo from "../images/ResetPassword.svg";
import closeIcon from "../images/close.svg";
import OtpInput from "react-otp-input";
import Backgroudui from "../shared/backgroudui";
import { useRAWApi } from "../../api/useApiHook";

export default function ForgetPassword(props: any) {
  const passRef = useRef() as any;
  const OTPlength = 6;
  const [formView, setFormView] = useState({
    title: "email",
    message: "Enter Your Registered Email Address",
  }) as any;
  const [otpValue, setotpValue] = useState("") as any;
  const [otpError, setotpError] = useState({
    status: false,
    message: "",
  }) as any;

  const [userDetails, setUserDetails] = useState("") as any;

  const [form] = Form.useForm();

  const { mutate: setPassword, isLoading: passwordLoading } = useRAWApi({
    url: "reset-password",
    onSuccess: ({ data }: any) => {
      if (data?.code === 200) {
        message.success(data?.message);
        props.close();
      }
    },
    onError: (error: any) => {
      message.error(error?.response?.data?.message);
      form.setFields([
        {
          name: "password",
          errors: [""],
        },
        {
          name: "password_confirmation",
          errors: [""],
        },
      ]);
      if (passRef?.current) {
        passRef.current.innerText = error?.response?.data?.message;
      }
    },
  });

  const { mutate: verifyOTP, isLoading: OTPLoading } = useRAWApi({
    url: "verify-otp",
    onSuccess: ({ data }: any) => {
      if (data?.code === 200) {
        message.success(data?.message);
        setUserDetails(data?.data);
        setFormView({
          title: "newPassword",
          message: "Set Password",
        });
      }
    },
    onError: (error: any) =>
      setotpError({
        status: true,
        message: "Invalid OTP",
      }),
  });

  const { mutate: sendEmail, isLoading: emailLoading } = useRAWApi({
    url: "forgot-password",
    onSuccess: ({ data }: any) => {
      if (data?.code === 200) {
        message.success(data?.message);
        setUserDetails({
          email: data?.data?.email,
        });
        setFormView({
          title: "OTP",
          message: "Enter 6-Digit OTP",
        });
      }
    },
    onError: (error: any) => {
      form.setFields([
        {
          name: "email",
          errors: [error?.response?.data?.message],
        },
      ]);
    },
  });

  const onFinish = (values: any) => {
    switch (formView.title) {
      case "email":
        sendEmail(values);
        break;
      case "OTP":
        verifyOTP({
          otp: otpValue,
          email: userDetails.email,
        });
        break;
      case "newPassword":
        setPassword({
          ...values,
          ...userDetails,
        });
        break;
    }
  };

  const formFieldsFor = () => {
    switch (formView.title) {
      case "email":
        return (
          <Form.Item
            name="email"
            label={<p className="label">Email Address</p>}
            rules={[
              { required: true, message: "Please Enter Email" },
              { type: "email", message: "Invalid Email" },
            ]}
          >
            <Input className="inputfield" />
          </Form.Item>
        );
      case "newPassword":
        return (
          <>
            <p className="text-xs text-red-500 capitalize" ref={passRef}></p>
            <Form.Item
              label={<p className="label">Enter New Password</p>}
              name="password"
              rules={[{ required: true, message: `Please Enter New Password` }]}
            >
              <Input.Password
                className="inputfield tracking-widest font-black"
                type="password"
              />
            </Form.Item>
            <Form.Item
              label={<p className="label">Re-Enter Password</p>}
              name="password_confirmation"
              dependencies={["password"]}
              rules={[
                { required: true, message: "required*" },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("password") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error("Passwords Do Not Match"));
                  },
                }),
              ]}
            >
              <Input.Password
                className="inputfield tracking-widest font-black"
                type="password"
              />
            </Form.Item>
          </>
        );
      case "OTP":
        return (
          <>
            <br />
            <OtpInput
              isInputNum
              value={otpValue}
              onChange={(otp: any) => {
                setotpValue(otp);
                otpError.status &&
                  setotpError({
                    status: false,
                    message: "",
                  });
              }}
              numInputs={OTPlength}
              separator={<span>&nbsp;&nbsp;</span>}
              inputStyle={{
                borderBottom: `1px solid rgba(${
                  otpError.status ? "255,0,0,1" : "0,0,0,.2"
                } )`,
                outline: "none",
                fontWeight: 500,
                width: "40px",
              }}
              shouldAutoFocus
            />
            {otpError.status && (
              <p className="text-red-500 py-2 text-xs">{otpError.message}</p>
            )}
            <br />
          </>
        );
    }
  };

  return (
    <ContainerWrap>
      <Backgroudui bgColor="transparent" pad="0px">
        <div
          className="grid place-items-center grid-cols-2 gap-0 "
          style={{
            width: "918px",
            height: "601px",
            borderRadius: "25.5px",
            overflow: "hidden",
          }}
        >
          <div
            className="text-white h-full w-full flex justify-center flex-col p-12 sm:hidden"
            style={{ background: "#1E1E1E" }}
          >
            <img
              src={logo}
              alt=""
              style={{
                height: "110px",
                position: "absolute",
                top: "70px",
                alignSelf: "start",
              }}
            />
            <h3
              className="text-white font-bold"
              style={{ fontSize: "20px", opacity: "0.9" }}
            >
              Forgot Your Password?
            </h3>
            <p className="opacity-50">
              Follow the steps and recover your password.
            </p>
            <ol
              className="list-decimal pl-4 pt-10 grid gap-2"
              style={{ fontSize: "11px" }}
            >
              <li>Enter your registered email address.</li>
              <li>Enter the OTP received on the provided email address.</li>
              <li>Enter the OTP in the text field.</li>
              <li>Enter your new password.</li>
              <li>
                Click on 'Submit' button and login with your new password.
              </li>
            </ol>
          </div>
          <div className="bg-white h-full w-full flex justify-center items-center flex-col p-12 relative">
            <span
              style={{ position: "absolute", top: "26.43px", right: "26.64px" }}
            >
              <img
                src={closeIcon}
                className="cursor-pointer"
                onClick={props.close}
              />
            </span>
            <div className="insideView">
              <h5 className="font-semibold capitalize text-lg">
                {formView.title}
              </h5>
              <Form
                form={form}
                name="forgetPass"
                layout="vertical"
                onFinish={onFinish}
                autoComplete="off"
              >
                {formFieldsFor()}
                <Button
                  type="primary"
                  className="btnform"
                  htmlType="submit"
                  disabled={emailLoading || OTPLoading || passwordLoading}
                  loading={emailLoading || OTPLoading || passwordLoading}
                >
                  <p className="py-5 px-8">
                    {OTPLoading || emailLoading || passwordLoading
                      ? "Loading..."
                      : "Submit"}
                  </p>
                </Button>
              </Form>
            </div>
          </div>
        </div>
      </Backgroudui>
    </ContainerWrap>
  );
}

const ContainerWrap = styled(motion.div)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 22;

  .insideView {
    h3 {
      font-size: 14px;
    }
    form {
      min-width: 320px;
    }
  }
`;
