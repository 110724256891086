import { useState } from "react";
import styled from "styled-components";
import logo from "../images/food_app_logo.svg";
import { Form, Input, Checkbox, message, Modal } from "antd";
import ForgetPassword from "./forgotpass";
import { useAppDispatch } from "../../global/hooks";
import { getLoginSuccess } from "../../global/authSlice";
import key from "../../key";
import Backgroudui from "../shared/backgroudui";
import { useRAWApi } from "../../api/useApiHook";

export default function Login() {
  const [form] = Form.useForm();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const dispatch = useAppDispatch();
  const [remember, setRemember] = useState(false);
  const { mutate, isLoading } = useRAWApi({
    url: "login",
    onSuccess: ({ data }: any) =>
      data?.code == 200 &&
      dispatch(
        getLoginSuccess({
          data: data?.data,
          remember,
        })
      ),
    onError: (error: any) => {
      message.error(error?.response?.data?.message);
      form.setFields([
        {
          name: "email",
          errors: [""],
        },
        {
          name: "password",
          errors: [""],
        },
      ]);
    },
  });

  const onFinish = (values: any) => mutate(values);

  const reset = () => form.resetFields();

  return (
    <>
      <Modal
        footer={false}
        centered
        visible={isModalVisible}
        onOk={() => setIsModalVisible(false)}
        onCancel={() => setIsModalVisible(false)}
        destroyOnClose
        bodyStyle={{
          padding: 0,
        }}
      >
        <ForgetPassword close={() => setIsModalVisible(false)} reset={reset} />
      </Modal>
      <Backgroudui pad="0rem">
        <LoginWrapper>
          <div className="w-screen h-screen grid place-items-center">
            <div
              className="cpnt p-12 rounded-2xl sm:w-full sm:h-full sm:rounded-none"
              style={{ background: "#1e1e1e" }}
            >
              <div className="hidden sm:block">
                <br />
                <br />
              </div>
              <div className="flex sm:justify-center">
                <img src={logo} alt="logo" className="w-12 sm:w-20" />
              </div>
              <br />
              <h1 className="text-2xl font-semibold text-white sm:text-center sm:text-3xl">
                Welcome To Admin!
              </h1>
              <p className="text-base font-normal opacity-70 text-white sm:text-center">
                Sign in to start your session
              </p>
              <br />
              <br />
              <Form
                name="login"
                form={form}
                layout="vertical"
                onFinish={onFinish}
                autoComplete="off"
              >
                <p className="text-white pb-2">Email</p>
                <Form.Item
                  name="email"
                  rules={[{ required: true }, { type: "email" }]}
                >
                  <Input className="inputfield" />
                </Form.Item>
                <p className="text-white pb-2">Password</p>
                <Form.Item name="password" rules={[{ required: true }]}>
                  <Input.Password className="inputfield" />
                </Form.Item>
                <div className="flex justify-between items-center">
                  <Checkbox
                    onChange={(ev: any) => setRemember(ev.target.checked)}
                  >
                    <p className="text-white">Remember me</p>
                  </Checkbox>
                  <p
                    className="text-yellow-400 cursor-pointer"
                    onClick={() => setIsModalVisible(true)}
                  >
                    Forgot Password?
                  </p>
                </div>
                <br />
                <br />
                <div className="flex justify-center">
                  <button
                    disabled={isLoading}
                    className={`cursor-pointer bg-white px-16 py-3 rounded-lg text-base font-bold ${
                      isLoading ? "opacity-70 pointer-events-none" : ""
                    }`}
                  >
                    {isLoading ? "Loading..." : "Login"}
                  </button>
                </div>
              </Form>
            </div>
          </div>
        </LoginWrapper>
      </Backgroudui>
    </>
  );
}

const LoginWrapper = styled.div`
  @media (min-width: 700px) {
    .cpnt {
      width: 504px;
    }
  }
  .ant-input,
  .ant-input-password,
  .ant-form-item-has-error {
    background-color: transparent !important;
    border-color: rgba(255, 255, 255, 0.3) !important;
    color: white !important;
    .ant-input-password-icon {
      color: white !important;
      position: relative !important;
      top: 5px !important;
      right: 2px !important;
    }
  }
`;
