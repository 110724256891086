import logo from "../images/food_app_logo.svg";
import BarLoader from "react-spinners/PropagateLoader";
import PulseLoader from "react-spinners/PulseLoader";

export default function Loader({ height = "85vh", pulse = false }: any) {
  return (
    <main className="px-12 py-3">
      <div className="flex justify-center items-center" style={{ height }}>
        <div className="grid place-items-center">
          <img
            style={{ background: "#0000001f" }}
            src={logo}
            alt="Food911"
            className={`px-2 pt-2 pb-1 rounded-md ${
              pulse ? "animate-pulse" : ""
            }`}
          />
          {!pulse && (
            <div className="pt-4">
              <BarLoader color="#929292" />
              &nbsp;&nbsp;&nbsp;
            </div>
          )}
        </div>
      </div>
    </main>
  );
}

export function MinLoader() {
  return <PulseLoader size={10} margin={5} />;
}

export function TableLoading() {
  return (
    <ul
      style={{
        width: "100%",
        minHeight: "100%",
        position: "absolute",
        top: "55px",
        left: "0",
        display: "flex",
        flexDirection: "column",
        rowGap: "10px",
      }}
    >
      <li className="h-10 w-full bg-gray-200 animate-pulse"></li>
      <li className="h-10 w-full bg-gray-200 animate-pulse"></li>
      <li className="h-10 w-full bg-gray-200 animate-pulse"></li>
      <li className="h-10 w-full bg-gray-200 animate-pulse"></li>
    </ul>
  );
}
