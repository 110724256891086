import key from "../key";
import axios from "axios";
import { useQuery, useMutation } from "react-query";
import { handleLogout } from "../components/shared/customValidators";

const api = axios.create({
  baseURL: key.BACK_ENDURL,
});

api.interceptors.request.use((req: any) => {
  req.headers.Authorization = localStorage.getItem("admin_api_token");
  return req;
});

api.interceptors.response.use(
  (res: any) => (res?.data?.code ===401 ? handleLogout() : res),
  (error: any) => {
    if (error?.response?.status === 401) {
      handleLogout();
    }
    return Promise.reject(error);
  }
);

// api.defaults.headers.common["X-localization"] = "en";

const getOne = (resource: string, id: any = null) =>
  api.get(`/${resource}?timestamp=${new Date().getTime()}`);

const getAll = (resource: any) => api.get(`/${resource}`);

const getCreate = (resource: string, data: any) =>
  api.post(`/${resource}`, data);

const getResponse = (resource: string, data: any) =>
  axios.post(key.BACK_ENDURL + resource, data, {
    headers: {
      Accept: "application/json",
    },
  });

const getDelete = (resource: string) => api.delete(`/${resource}`);

/*
 rest contains the properties of react query , can pass directly from the invocation of these functions

*/

export const useRAWApi = ({ url, ...rest }: any) =>
  useMutation((data) => getResponse(url, data), {
    ...rest,
    cacheTime: 0,
    retry: false,
  }) as any;

export const useCreate = ({ url, ...rest }: any) =>
  useMutation((data) => getCreate(url, data), {
    ...rest,
    retry: false,
    // cacheTime: 0,
  }) as any;

export const useFetchAll = ({ url, ...rest }: any) =>
  useQuery(url, () => getAll(url), {
    ...rest,
    retry: false,
    select: ({ data }: any) => data?.data,
  }) as any;

export const useFetchOne = ({ url, ...rest }: any) =>
  useQuery(url, () => getOne(url), {
    ...rest,
    cacheTime: 0,
    retry: false,
    refetchOnWindowFocus: false,
    select: ({ data }: any) => data?.data,
  }) as any;

export const useDelete = ({ url, ...rest }: any) =>
  useMutation((id) => getDelete(url + id), {
    ...rest,
    retry: false,
    cacheTime: 0,
  }) as any;