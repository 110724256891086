import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "./store";

export interface authState {
  isAuthenticated: boolean;
  status: "idle" | "loading" | "failed";
  isError: number | null;
  user: any | null;
  lng: string;
}

let token = localStorage.getItem("admin_api_token")
  ? localStorage.getItem("admin_api_token")
  : null;

let user = localStorage.getItem("user")
  ? JSON.parse(localStorage.getItem("user") as any)
  : null;

let lng = localStorage.getItem("i18nextLng")
  ? (localStorage.getItem("i18nextLng") as any) === "en-GB"
    ? "en"
    : (localStorage.getItem("i18nextLng") as any)
  : "en";

const initialState: authState = {
  isAuthenticated: token ? true : false,
  status: "idle",
  isError: null,
  user,
  lng: lng,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    getLogin: (state) => {
      state.status = "loading";
    },
    getLoginSuccess: (state, { payload }) => {
      const { remember, data } = payload;
      const { api_token, assigned_permission, ...rest } = data;
      state.isAuthenticated = !!data;
      localStorage.setItem("admin_api_token", api_token);
      localStorage.setItem("user", JSON.stringify(rest));
      state.user = {
        assigned_permission,
        ...rest,
      };
      state.status = "idle";
    },
    setPermissions: (state, { payload }) => {
      state.user.assigned_permission = payload;
    },
    getLoginError: (state) => {
      state.status = "idle";
    },

    getError: (state, { payload }) => {
      state.isError = payload;
      state.status = "idle";
    },
    getLng: (state, { payload }) => {
      state.lng = payload;
    },
    setUser: (state, { payload }) => {
      state.user = {
        ...state.user,
        ...payload,
      };
    },
    setError: (state, { payload }) => {
      state.isError = payload;
    },
  },
});

export const {
  getLogin,
  getError,
  getLng,
  getLoginSuccess,
  getLoginError,
  setPermissions,
  setUser,
  setError,
} = authSlice.actions;
export const selectAuth = (state: RootState) => state.auth;

export default authSlice.reducer;
