import React, { useState } from "react";
import { AnimatePresence } from "framer-motion";
import { Route, Switch, useRouteMatch, useLocation } from "react-router-dom";
import { Layout } from "antd";
import SideBar from "./sideBar";
import Loader from "./shared/loader";
import { useAppSelector } from "../global/hooks";
import { selectAuth } from "../global/authSlice";
import { selectHeader } from "../global/headerSlice";
import HeaderSec from "./header";
const ProtectedRoute = React.lazy(() => import("./ProtectedRoutes"));
const EditFare = React.lazy(() => import("./pages/DeliveryPartners/EditFare"));
const NotFound = React.lazy(() => import("./pages/notFound"));
const SendNotification = React.lazy(
  () => import("./pages/ManageNotifications/SendNotification")
);
const Place = React.lazy(() => import("./pages/Place/Index"));
const AddCity = React.lazy(() => import("./pages/Place/AddCity"));
const EditCity = React.lazy(() => import("./pages/Place/EditCity"));
const AddCountry = React.lazy(() => import("./pages/Place/AddCountry"));
const EditCountry = React.lazy(() => import("./pages/Place/EditCountry"));
const UnAuthPermission = React.lazy(() => import("./shared/UnAuthPermission"));
const EditSubAdminUser = React.lazy(
  () => import("./pages/SubAdminRole/EditSubAdminUser")
);

const AddMasterCategory = React.lazy(
  () => import("./pages/MasterCategory/AddMasterCategory")
);
const EditMasterCategory = React.lazy(
  () => import("./pages/MasterCategory/EditMasterCategory")
);
const EditDeliveryPartnerDetails = React.lazy(
  () => import("./pages/DeliveryPartners/EditDeliveryPartnerDetails")
);
const EditBanner = React.lazy(() => import("./pages/ManageBanners/EditBanner"));
const EditTag = React.lazy(() => import("./pages/Restaurants/tags/EditTag"));

const EditPromo = React.lazy(() => import("./pages/Promo/EditPromo"));
const Logs = React.lazy(() => import("./pages/ManageCustomers/Logs"));
const CustomerDetails = React.lazy(
  () => import("./pages/ManageCustomers/CustomerDetails")
);
const AddNewUser = React.lazy(
  () => import("./pages/DeliveryPartners/AddNewUser")
);
const ManageFare = React.lazy(
  () => import("./pages/DeliveryPartners/ManageFare")
);
const AddNewFare = React.lazy(
  () => import("./pages/DeliveryPartners/AddNewFare")
);
const DeliveryPartnerDetails = React.lazy(
  () => import("./pages/DeliveryPartners/DeliveryPartnerDetails")
);
const SettelmentDetails = React.lazy(
  () => import("./pages/DeliveryPartners/SettelmentDetails")
);
const BannerDetails = React.lazy(
  () => import("./pages/ManageBanners/BannerDetails")
);
const AddBanner = React.lazy(() => import("./pages/ManageBanners/AddBanner"));
const Details = React.lazy(() => import("./pages/SubAdminRole/Details"));
const AddSubAdminUser = React.lazy(
  () => import("./pages/SubAdminRole/AddSubAdminUser")
);
const PromoDetails = React.lazy(() => import("./pages/Promo/PromoDetails"));
const AddPromo = React.lazy(() => import("./pages/Promo/AddPromo"));
const RestaurantInvoice = React.lazy(
  () => import("./pages/Payables/RestaurantInvoice")
);
const DeliveryPartnerInvoice1 = React.lazy(
  () => import("./pages/Payables/DeliveryPartnerInvoice1")
);
const SupportMemberDetails = React.lazy(
  () => import("./pages/CustomerSupport/SupportMemberDetails")
);
const Inbox = React.lazy(() => import("./pages/CustomerSupport/Inbox"));
const AddCategory = React.lazy(
  () => import("./pages/Restaurants/categories/AddCategory")
);
const AddMenu = React.lazy(() => import("./pages/Restaurants/menu/AddMenu"));
const RestaurantDetails = React.lazy(
  () => import("./pages/Restaurants/details")
);
const Tags = React.lazy(() => import("./pages/Restaurants/tags/Tags"));
const AddTag = React.lazy(() => import("./pages/Restaurants/tags/AddTag"));
const Promotions = React.lazy(
  () => import("./pages/Restaurants/Promotions/index")
);

const Dashboard = React.lazy(() => import("./pages/dashboard"));
const ManageCustomers = React.lazy(() => import("./pages/ManageCustomers"));
const Restaurants = React.lazy(() => import("./pages/Restaurants"));
const AddRestaurant = React.lazy(
  () => import("./pages/Restaurants/addRestuarent")
);

const DeliveryPartners = React.lazy(() => import("./pages/DeliveryPartners"));
const Orders = React.lazy(() => import("./pages/Orders"));
const ManageBanners = React.lazy(() => import("./pages/ManageBanners"));
const Payables = React.lazy(() => import("./pages/Payables"));
const ManageNotifications = React.lazy(
  () => import("./pages/ManageNotifications")
);
const SubAdminRole = React.lazy(() => import("./pages/SubAdminRole"));
const Promo = React.lazy(() => import("./pages/Promo"));
const CustomerSupport = React.lazy(() => import("./pages/CustomerSupport"));
const DashboardSales = React.lazy(
  () => import("../components/pages/dashboard/Sales")
);
const DashboardRestaurants = React.lazy(
  () => import("../components/pages/dashboard/Restaurants")
);
const DashboardCustomers = React.lazy(
  () => import("../components/pages/dashboard/Customers")
);
const DashbaordCouriesPartners = React.lazy(
  () => import("../components/pages/dashboard/CouriesPartners")
);
const OrderDetails = React.lazy(() => import("./pages/Orders/orderDetails"));
const Food911Details = React.lazy(() => import("./pages/Food911Details"));
const Profile = React.lazy(() => import("./pages/Profile"));
const ChangePassword = React.lazy(() => import("./pages/ChangePassword"));
const MasterCategory = React.lazy(() => import("./pages/MasterCategory/Index"));

const HomeSettings = React.lazy(() => import("./pages/homeSetting"));
const AddSettings = React.lazy(() => import("./pages/homeSetting/addSettings"));
const EditSettings = React.lazy(
  () => import("./pages/homeSetting/editSettings")
);

const AddPromotions = React.lazy(
  () => import("./pages/Restaurants/Promotions/AddPromotion")
);
const EditPromotions = React.lazy(
  () => import("./pages/Restaurants/Promotions/EditPromotion")
);
const LoyalityPoints = React.lazy(() => import("./pages/LoyalityPoints"));

const { Content } = Layout;
export const inputPattern = /^(?:\d{1,})(?:\.\d{1,})?$/;

export default function DashboardIndex() {
  const [collapsed, setCollapsed] = useState(false);
  const toggle = () => setCollapsed(!collapsed);
  let { path } = useRouteMatch();
  const location = useLocation();
  const {
    user: { assigned_permission },
  } = useAppSelector(selectAuth);
  const headerDetails = useAppSelector(selectHeader);
  return (
    <Layout>
      <SideBar click={toggle} collapsed={collapsed} color={false} />
      <Content
        className="h-screen flex flex-col"
        style={{
          backgroundColor: "#F5f5f5",
        }}
      >
        <HeaderSec />
        <div className="h-screen overflow-auto" id="app-content-container">
          {!headerDetails.loading && assigned_permission ? (
            <AnimatePresence exitBeforeEnter initial={false}>
              <React.Suspense fallback={<Loader />}>
                <Switch location={location} key={location.pathname}>
                  <ProtectedRoute
                    title="Dashboard"
                    exact
                    path={`${path}`}
                    component={Dashboard}
                    permissionsFor="Dashboard"
                    targetPermissions={["View"]}
                  />
                  <ProtectedRoute
                    title="Dashboard"
                    exact
                    path={`${path}/home`}
                    component={Dashboard}
                    permissionsFor="Dashboard"
                    targetPermissions={["View"]}
                  />
                  <ProtectedRoute
                    title="Dashboard"
                    exact
                    path={`${path}/home/sales`}
                    component={DashboardSales}
                    permissionsFor="Dashboard"
                    targetPermissions={["View"]}
                  />
                  <ProtectedRoute
                    title="Dashboard"
                    exact
                    path={`${path}/home/restaurants`}
                    component={DashboardRestaurants}
                    permissionsFor="Dashboard"
                    targetPermissions={["View"]}
                  />
                  <ProtectedRoute
                    title="Dashboard"
                    exact
                    path={`${path}/home/customers`}
                    component={DashboardCustomers}
                    permissionsFor="Dashboard"
                    targetPermissions={["View"]}
                  />
                  <ProtectedRoute
                    title="Dashboard"
                    exact
                    path={`${path}/home/courier-partners`}
                    component={DashbaordCouriesPartners}
                    permissionsFor="Dashboard"
                    targetPermissions={["View"]}
                  />
                  <ProtectedRoute
                    title="Manage Customers"
                    exact
                    path={`${path}/manage-customers`}
                    component={ManageCustomers}
                    permissionsFor="Manage Customers"
                    targetPermissions={["List"]}
                  />
                  <ProtectedRoute
                    title="Manage Customers"
                    exact
                    path={`${path}/manage-customers/logs`}
                    component={Logs}
                    permissionsFor="Manage Customers"
                    targetPermissions={["List"]}
                  />
                  <ProtectedRoute
                    title="Manage Customers"
                    exact
                    path={`${path}/manage-customers/customer-details/:id`}
                    component={CustomerDetails}
                    permissionsFor="Manage Customers"
                    targetPermissions={["List", "View"]}
                  />

                  <ProtectedRoute
                    title="Master Category"
                    exact
                    path={`${path}/master-category`}
                    component={MasterCategory}
                    permissionsFor=""
                  />

                  <ProtectedRoute
                    title="Master Category"
                    exact
                    path={`${path}/master-category/add-master-category`}
                    component={AddMasterCategory}
                    permissionsFor=""
                  />
                  <ProtectedRoute
                    title="Master Category"
                    exact
                    path={`${path}/master-category/edit/:id`}
                    component={EditMasterCategory}
                    permissionsFor=""
                  />

                  <ProtectedRoute
                    title="Restaurant"
                    exact
                    path={`${path}/restaurants`}
                    component={Restaurants}
                    permissionsFor="Restaurants"
                    targetPermissions={["List"]}
                  />

                  <ProtectedRoute
                    title="Restaurant"
                    exact
                    path={`${path}/restaurants/details/:id`}
                    component={RestaurantDetails}
                    permissionsFor="Restaurants"
                    targetPermissions={["List", "View"]}
                  />
                  <ProtectedRoute
                    title="Restaurant"
                    exact
                    path={`${path}/restaurants/add-restaurant`}
                    component={AddRestaurant}
                    permissionsFor="Restaurants"
                    targetPermissions={["List", "Add"]}
                  />

                  <ProtectedRoute
                    title="Restaurant"
                    exact
                    path={`${path}/restaurants/add-restaurant/add-category`}
                    component={AddCategory}
                    permissionsFor="Restaurants"
                    targetPermissions={["List", "Add"]}
                  />
                  <ProtectedRoute
                    title="Restaurant"
                    exact
                    path={`${path}/restaurants/details/:id/add-category`}
                    component={AddCategory}
                    permissionsFor="Restaurants"
                    targetPermissions={["List", "View", "Edit"]}
                  />
                  <ProtectedRoute
                    title="Restaurant"
                    exact
                    path={`${path}/restaurants/details/:id/add-menu`}
                    component={AddMenu}
                    permissionsFor="Restaurants"
                    targetPermissions={["List", "View", "Edit"]}
                  />
                  <ProtectedRoute
                    title="Restaurant"
                    exact
                    path={`${path}/restaurants/details/:id/tags`}
                    component={
                      assigned_permission["Tag"]?.includes("List")
                        ? Tags
                        : UnAuthPermission
                    }
                    permissionsFor="Restaurants"
                    targetPermissions={["List", "View"]}
                  />

                  <ProtectedRoute
                    title="Restaurant"
                    exact
                    path={`${path}/restaurants/details/:id/tags/addtag`}
                    component={
                      assigned_permission["Tag"]?.includes("List") &&
                      assigned_permission["Tag"]?.includes("Add")
                        ? AddTag
                        : UnAuthPermission
                    }
                    permissionsFor="Restaurants"
                    targetPermissions={["List", "View"]}
                  />

                  <ProtectedRoute
                    title="Restaurant"
                    exact
                    path={`${path}/restaurants/details/:id/tags/edit-tag/:tag_id`}
                    component={
                      assigned_permission["Tag"]?.includes("List") &&
                      assigned_permission["Tag"]?.includes("Edit")
                        ? EditTag
                        : UnAuthPermission
                    }
                    permissionsFor="Restaurants"
                    targetPermissions={["List", "View"]}
                  />

                  <ProtectedRoute
                    title="Restaurant"
                    exact
                    path={`${path}/restaurants/details/:id/promotions`}
                    component={Promotions}
                    permissionsFor="Restaurants"
                    targetPermissions={["List", "View"]}
                  />

                  <ProtectedRoute
                    title="Restaurant"
                    exact
                    path={`${path}/restaurants/details/:id/promotions/add-promotion`}
                    component={AddPromotions}
                    permissionsFor="Restaurants"
                    targetPermissions={["List", "View", "Add"]}
                  />

                  <ProtectedRoute
                    title="Restaurant"
                    exact
                    path={`${path}/restaurants/details/:id/promotions/edit/:promotion_id`}
                    component={EditPromotions}
                    permissionsFor="Restaurants"
                    targetPermissions={["List", "View", "Edit"]}
                  />

                  <ProtectedRoute
                    title="Delivery Partners"
                    exact
                    path={`${path}/delivery-partners`}
                    component={DeliveryPartners}
                    permissionsFor="Delivery Partners"
                    targetPermissions={["List"]}
                  />
                  <ProtectedRoute
                    title="Delivery Partners"
                    exact
                    path={`${path}/delivery-partners/add-new-user`}
                    component={AddNewUser}
                    permissionsFor="Delivery Partners"
                    targetPermissions={["List", "Add"]}
                  />

                  <ProtectedRoute
                    title="Delivery Partners"
                    exact
                    path={`${path}/delivery-partners/manage-fare`}
                    component={ManageFare}
                    permissionsFor="Delivery Partners"
                    targetPermissions={["List"]}
                  />
                  <ProtectedRoute
                    title="Delivery Partners"
                    exact
                    path={`${path}/delivery-partners/manage-fare/add-new-fare`}
                    component={AddNewFare}
                    permissionsFor="Delivery Partners"
                    targetPermissions={["List"]}
                  />
                  <ProtectedRoute
                    title="Delivery Partners"
                    exact
                    path={`${path}/delivery-partners/manage-fare/edit-fare/:id`}
                    component={EditFare}
                    permissionsFor="Delivery Partners"
                    targetPermissions={["Edit"]}
                  />

                  <ProtectedRoute
                    title="Delivery Partners"
                    exact
                    path={`${path}/delivery-partners/details/:id`}
                    component={DeliveryPartnerDetails}
                    permissionsFor="Delivery Partners"
                    targetPermissions={["List", "View"]}
                  />

                  <ProtectedRoute
                    title="Delivery Partners"
                    exact
                    path={`${path}/delivery-partners/edit/:id`}
                    component={EditDeliveryPartnerDetails}
                    permissionsFor="Delivery Partners"
                    targetPermissions={["List", "View", "Edit"]}
                  />

                  <ProtectedRoute
                    title="Delivery Partners"
                    exact
                    path={`${path}/delivery-partners/settlement-details/:id`}
                    component={SettelmentDetails}
                    permissionsFor="Delivery Partners"
                    targetPermissions={["List", "View"]}
                  />

                  <ProtectedRoute
                    title="Location"
                    exact
                    path={`${path}/place`}
                    component={Place}
                    permissionsFor=""
                  />

                  <ProtectedRoute
                    title="Location"
                    exact
                    path={`${path}/place/city/add/:id`}
                    component={AddCity}
                    permissionsFor=""
                  />

                  <ProtectedRoute
                    title="Location"
                    exact
                    path={`${path}/place/city/edit/:country_id/:city_id`}
                    component={EditCity}
                    permissionsFor=""
                  />

                  <ProtectedRoute
                    title="Location"
                    exact
                    path={`${path}/place/country/add`}
                    component={AddCountry}
                    permissionsFor=""
                  />

                  <ProtectedRoute
                    title="Location"
                    exact
                    path={`${path}/place/country/edit/:id`}
                    component={EditCountry}
                    permissionsFor=""
                  />

                  <ProtectedRoute
                    title="Orders"
                    exact
                    path={`${path}/orders`}
                    component={Orders}
                    permissionsFor="Orders"
                    targetPermissions={["List"]}
                  />

                  <ProtectedRoute
                    title="Orders"
                    exact
                    path={`${path}/orders/info/:id`}
                    component={OrderDetails}
                    permissionsFor="Orders"
                    targetPermissions={["List", "View"]}
                  />

                  <ProtectedRoute
                    title="Manage Banners"
                    exact
                    path={`${path}/manage-banners`}
                    component={ManageBanners}
                    permissionsFor="Manage Banners"
                    targetPermissions={["List"]}
                  />

                  <ProtectedRoute
                    title="Manage Banners"
                    exact
                    path={`${path}/manage-banners/details/:id`}
                    component={BannerDetails}
                    permissionsFor="Manage Banners"
                    targetPermissions={["List", "View"]}
                  />

                  <ProtectedRoute
                    title="Manage Banners"
                    exact
                    path={`${path}/manage-banners/details/:id/edit`}
                    component={EditBanner}
                    permissionsFor="Manage Banners"
                    targetPermissions={["List", "View", "Edit"]}
                  />

                  <ProtectedRoute
                    title="Manage Banners"
                    exact
                    path={`${path}/manage-banners/add-banner`}
                    component={AddBanner}
                    permissionsFor="Manage Banners"
                    targetPermissions={["List", "Add"]}
                  />
                  <ProtectedRoute
                    title="Payables"
                    exact
                    path={`${path}/payables`}
                    component={Payables}
                    permissionsFor="Payables"
                    targetPermissions={["List"]}
                  />

                  <ProtectedRoute
                    title="Payables"
                    exact
                    path={`${path}/payables/restaurants-invoice/:id`}
                    component={RestaurantInvoice}
                    permissionsFor="Payables"
                    targetPermissions={["List", "View"]}
                  />

                  <ProtectedRoute
                    title="Payables"
                    exact
                    path={`${path}/payables/delivery-partner-invoice/:id`}
                    component={DeliveryPartnerInvoice1}
                    permissionsFor="Payables"
                    targetPermissions={["List", "View"]}
                  />

                  <ProtectedRoute
                    title="Manage Notifications"
                    exact
                    path={`${path}/manage-notifications`}
                    component={ManageNotifications}
                    permissionsFor="Manage Notifications"
                    targetPermissions={["List"]}
                  />

                  <ProtectedRoute
                    title="Manage Notifications"
                    exact
                    path={`${path}/manage-notifications/send-notification`}
                    component={SendNotification}
                    permissionsFor="Manage Notifications"
                    targetPermissions={["Add"]}
                  />

                  <ProtectedRoute
                    title="Sub-admin Role"
                    exact
                    path={`${path}/sub-admin-role`}
                    component={SubAdminRole}
                    permissionsFor="Sub Admin Role"
                    targetPermissions={["List"]}
                  />

                  <ProtectedRoute
                    title="Sub-admin Role"
                    exact
                    path={`${path}/sub-admin-role/details/:id`}
                    component={Details}
                    permissionsFor="Sub Admin Role"
                    targetPermissions={["List", "View"]}
                  />
                  <ProtectedRoute
                    title="Sub-admin Role"
                    exact
                    path={`${path}/sub-admin-role/add-new-user`}
                    component={AddSubAdminUser}
                    permissionsFor="Sub Admin Role"
                    targetPermissions={["List", "Add"]}
                  />

                  <ProtectedRoute
                    title="Sub-admin Role"
                    exact
                    path={`${path}/sub-admin-role/edit/:id`}
                    component={EditSubAdminUser}
                    permissionsFor="Sub Admin Role"
                    targetPermissions={["List", "View", "Edit"]}
                  />

                  <ProtectedRoute
                    title="Promo"
                    exact
                    path={`${path}/promo`}
                    component={Promo}
                    permissionsFor="Promo"
                    targetPermissions={["List"]}
                  />

                  <ProtectedRoute
                    title="Promo"
                    exact
                    path={`${path}/promo/details/:id`}
                    component={PromoDetails}
                    permissionsFor="Promo"
                    targetPermissions={["List", "View"]}
                  />

                  <ProtectedRoute
                    title="Promo"
                    exact
                    path={`${path}/promo/details/:id/edit`}
                    component={EditPromo}
                    permissionsFor="Promo"
                    targetPermissions={["List", "View", "Edit"]}
                  />

                  <ProtectedRoute
                    title="Promo"
                    exact
                    path={`${path}/promo/add-new-promo`}
                    component={AddPromo}
                    permissionsFor="Promo"
                    targetPermissions={["List", "Add"]}
                  />

                  <ProtectedRoute
                    title="Home Settings"
                    exact
                    path={`${path}/home-settings`}
                    component={HomeSettings}
                    permissionsFor=""
                  />

                  <ProtectedRoute
                    title="Home Settings"
                    exact
                    path={`${path}/home-settings/add`}
                    component={AddSettings}
                    permissionsFor=""
                  />

                  <ProtectedRoute
                    title="Home Settings"
                    exact
                    path={`${path}/home-settings/edit/:id/:setting_id`}
                    component={EditSettings}
                    permissionsFor=""
                  />

                  <ProtectedRoute
                    title="Restaurant"
                    exact
                    path={`${path}/restaurants/add-restaurant/add-menu`}
                    component={AddMenu}
                    permissionsFor="Restaurants"
                    targetPermissions={["List", "Add"]}
                  />

                  <ProtectedRoute
                    title="Customer Support"
                    exact
                    path={`${path}/customer-support`}
                    component={CustomerSupport}
                    permissionsFor="Support"
                    targetPermissions={["View"]}
                  />

                  <ProtectedRoute
                    title="Customer Support"
                    exact
                    path={`${path}/customer-support/support-member-details/:id`}
                    component={SupportMemberDetails}
                    permissionsFor="Support"
                    targetPermissions={["View"]}
                  />

                  <ProtectedRoute
                    title="Customer Support"
                    exact
                    path={`${path}/customer-support/inbox`}
                    component={Inbox}
                    permissionsFor="Support"
                    targetPermissions={["Add", "View"]}
                  />

                  <ProtectedRoute
                    title="Food911 Details"
                    exact
                    path={`${path}/food911details`}
                    component={Food911Details}
                    permissionsFor=""
                  />

                  <ProtectedRoute
                    title="Profile"
                    exact
                    path={`${path}/profile`}
                    component={Profile}
                    permissionsFor=""
                  />

                  <ProtectedRoute
                    title="Change Password"
                    exact
                    path={`${path}/change-password`}
                    component={ChangePassword}
                    permissionsFor=""
                  />

                  <ProtectedRoute
                    title="Loyality Points"
                    exact
                    path={`${path}/loyality`}
                    component={LoyalityPoints}
                    permissionsFor=""
                  />

                  <Route exact path="**" component={NotFound} />
                </Switch>
              </React.Suspense>
            </AnimatePresence>
          ) : (
            <Loader />
          )}
        </div>
      </Content>
    </Layout>
  );
}
